export const master_pincode_go_digit =  [
    {
      "Pincode": 110001,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110002,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110003,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110004,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110005,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110006,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110007,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110008,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110009,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110010,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110011,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110012,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110013,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110014,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110015,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110016,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110017,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110018,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110019,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110020,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110021,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110022,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110023,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110024,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110025,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110026,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110027,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110028,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110029,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110030,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110031,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110032,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110033,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110034,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110035,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110036,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110037,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110038,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110039,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110040,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110041,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110042,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110043,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110044,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110045,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110046,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110047,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110048,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110049,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110051,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110052,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110053,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110054,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110055,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110056,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110057,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110058,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110059,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110060,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110061,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110062,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110063,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110064,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110065,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110066,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110067,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110068,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110069,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110070,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110071,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110072,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110073,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110074,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110075,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110076,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110077,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110078,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110080,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110081,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110082,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110083,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110084,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110085,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110086,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110087,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110088,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110089,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110090,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110091,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110092,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110093,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110094,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110095,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110096,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 110097,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Delhi",
      "State Code": 7
    },
    {
      "Pincode": 121001,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121002,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121003,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121004,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121005,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121006,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121007,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121008,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121009,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121010,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121012,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121013,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121101,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121102,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121103,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121103,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121105,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121105,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121106,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121106,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121106,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "New Delhi",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121106,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "New Delhi",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121107,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 121107,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Faridabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122001,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122002,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122003,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122004,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122005,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122006,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122007,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122008,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122009,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122010,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122011,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122012,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122015,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122016,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122017,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122018,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122022,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurugram",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122050,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122051,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122052,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122101,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122102,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122103,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122104,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122104,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122105,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122106,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122107,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122107,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122108,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122108,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122413,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122414,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122414,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122502,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122502,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122503,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122503,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122504,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122504,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122505,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122506,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122506,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122508,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 122508,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123031,
      "Zone": "A",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123106,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123106,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123106,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123106,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123401,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bikaner",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123401,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123401,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123401,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gurgaon",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123401,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123401,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahendragarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewari",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 123506,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "GURGAON",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhajjar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhajjar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhajjar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahadurgarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhajjar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhajjar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhajjar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhajjar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhajjar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhajjar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhajjar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhajjar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhajjar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahadurgarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahadurgarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahadurgarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahadurgarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahadurgarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahadurgarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahadurgarh",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 124514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtak",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125050,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125053,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125054,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125058,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125075,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125076,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125077,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125078,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hisar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehabad",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 125201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirsa",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 126101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jind",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 126102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jind",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 126110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jind",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 126111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jind",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 126112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jind",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 126113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jind",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 126114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jind",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 126115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jind",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 126116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jind",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 126125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jind",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 126152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jind",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Charkhi Dadri",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 127310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhiwani",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 131409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajound",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132054,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurukshetra",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panipat",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 132157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karnal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yamuna Nagar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yamuna Nagar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yamuna Nagar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 133302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambala",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 134205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panchkula",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 135001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yamuna Nagar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 135002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yamuna Nagar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 135003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yamuna Nagar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 135004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yamuna Nagar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 135021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yamuna Nagar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 135101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yamuna Nagar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 135102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yamuna Nagar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 135103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yamuna Nagar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 135106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yamuna Nagar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 135133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yamuna Nagar",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaithal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaithal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaithal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaithal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurukshetra",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaithal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaithal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaithal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurukshetra",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaithal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaithal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaithal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaithal",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurukshetra",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurukshetra",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurukshetra",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurukshetra",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurukshetra",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurukshetra",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurukshetra",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurukshetra",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurukshetra",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 136156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurukshetra",
      "State": "Haryana",
      "State Code": 6
    },
    {
      "Pincode": 140001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ropar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ropar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ropar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ropar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ropar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ropar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 140119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ropar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 140125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ropar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ropar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 140133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ropar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehgarh Sahib",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehgarh Sahib",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehgarh Sahib",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehgarh Sahib",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehgarh Sahib",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehgarh Sahib",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehgarh Sahib",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 140901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehgarh Sahib",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehgarh Sahib",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehgarh Sahib",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 141801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142050,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142053,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142054,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142056,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142057,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142058,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 142060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathankot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathankot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tarn Taran",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 143606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amritsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144624,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144626,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144628,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144629,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144629,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144631,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144632,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144633,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalandhar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 144819,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kapurthala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathankot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathankot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathankot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathankot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathankot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathankot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathankot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathankot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gurdaspur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 145101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathankot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawanshahr",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 146116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshiarpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehgarh Sahib",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patiala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 147301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehgarh Sahib",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barnala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barnala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barnala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barnala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barnala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barnala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barnala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barnala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barnala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barnala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ludhiana",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barnala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangrur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 148109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barnala",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridkot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridkot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridkot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridkot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridkot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridkot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridkot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridkot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridkot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mansa",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mansa",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mansa",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mansa",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mansa",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mansa",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mansa",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mansa",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mansa",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bathinda",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 151510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mansa",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faridkot",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moga",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muktsar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fazilka",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 152132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozpur",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 160001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 160004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rupnagar",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 160015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 160059,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 160062,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 160071,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 160101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandigarh",
      "State": "Chandigarh",
      "State Code": 4
    },
    {
      "Pincode": 160103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 160104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mohali",
      "State": "Punjab",
      "State Code": 3
    },
    {
      "Pincode": 171001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 171301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lahul and Spiti",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lahul and Spiti",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lahul and Spiti",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kinnaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 172201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimla",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirmaur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 173235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalagarh",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solan",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur (HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundarnagar",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 174505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175050,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lahul and Spiti",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lahul and Spiti",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lahul and Spiti",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lahul and Spiti",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lahul and Spiti",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 175143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kullu",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharamshala",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176053,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176054,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176056,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176057,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharamshala",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176058,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176059,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176061,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176062,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176063,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176064,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176065,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176066,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176071,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176073,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176075,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176076,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176077,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176081,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176082,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176083,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176084,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176085,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176086,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176086,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176087,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176087,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176088,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176088,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176089,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176090,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176091,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176092,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176093,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176094,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176095,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176096,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176097,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176098,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palampur",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandi",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Padhar",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176200,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharamshala",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharamshala",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharamshala",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dalhousie",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dalhousie",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamba",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 176601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kangra",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Una",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 177601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur(HP)",
      "State": "Himachal Pradesh",
      "State Code": 2
    },
    {
      "Pincode": 180001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 180020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 181224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "KARGIL",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 182101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Doda",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Reasi",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Reasi",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 182320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Poonch",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 184206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kathua",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Poonch",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Poonch",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Poonch",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jammu",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udhampur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Poonch",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Reasi",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 185234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajauri",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 190025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pulwama",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganderbal",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 191203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Qazigund",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pulwama",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pulwama",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pulwama",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pulwama",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pulwama",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pulwama",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pulwama",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kulgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pulwama",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pulwama",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pulwama",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Zoora",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pulwama",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anantnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pulwama",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 192401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kupwara",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kupwara",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kupwara",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kupwara",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kupwara",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kupwara",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthnag",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kupwara",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srinagar",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budgam",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bandipur",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 193504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baramulla",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 194101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Leh",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 194102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kargil",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 194103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kargil",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 194104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Leh",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 194105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kargil",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 194106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Leh",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 194109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kargil",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 194201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Leh",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 194301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kargil",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 194302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kargil",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 194401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Leh",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 194402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Leh",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 194403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kargil",
      "State": "Jammu and Kashmir",
      "State Code": 1
    },
    {
      "Pincode": 194404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Leh",
      "State": "Ladakh",
      "State Code": 38
    },
    {
      "Pincode": 201001,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201002,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201003,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201004,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201005,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201006,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201007,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201008,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201008,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Noida",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201009,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201010,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201011,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201012,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201013,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201014,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201015,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201016,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201017,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201019,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201102,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201103,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201201,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201201,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201204,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201204,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201206,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201301,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Noida",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201302,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Noida",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201302,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Noida",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201303,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Noida",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201304,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201304,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Noida",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201305,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201305,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Noida",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201306,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "3/5/2020",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201306,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "3/5/2020",
      "City": "Noida",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201307,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201307,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Noida",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201308,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Noida",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201309,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201309,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Noida",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201310,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201310,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Noida",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201311,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201312,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201313,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 201314,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hathras",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hathras",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hathras",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202280,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202281,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202282,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202393,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Debai",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 202413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kundarki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203131,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203131,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203131,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203131,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203135,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203135,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203141,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203141,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203141,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203141,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203155,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203155,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203155,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203155,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203201,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203201,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203202,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203202,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203203,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203203,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203203,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203203,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203207,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203209,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203209,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gautam Buddha Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203389,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203390,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203391,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203392,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203393,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203394,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203395,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203396,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203397,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203398,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203399,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 203412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bulandshahr",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 204101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hathras",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 204102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 204102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hathras",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 204211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 204211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hathras",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 204212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 204213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 204214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 204214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hathras",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 204215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 204216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aligarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205261,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205262,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205262,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205263,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205264,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205265,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205267,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205268,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 205304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mainpuri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206244,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206248,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206249,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206252,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206253,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etawah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206255,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Auraiya",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 206301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207244,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207248,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207249,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 207403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Etah",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 208027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Dehat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanpur Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209652,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209720,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209721,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209721,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209723,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209724,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209725,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209726,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209727,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209728,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209729,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209731,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209732,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209733,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209734,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209735,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209736,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209738,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209739,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209743,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209745,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209747,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannauj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209749,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Farrukhabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209821,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209825,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209827,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209831,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209841,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209859,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209861,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209862,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209863,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209864,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209865,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209866,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209867,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209868,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209869,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209870,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 209871,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitrakoot",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitrakoot",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitrakoot",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitrakoot",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitrakoot",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitrakoot",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitrakoot",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitrakoot",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahoba",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahoba",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210431,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210433,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahoba",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahoba",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hamirpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 210507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahoba",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 211019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaushambi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212631,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212635,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212641,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212645,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212650,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212652,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212653,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212654,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212655,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212656,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212657,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212658,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212659,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212664,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 212665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Fatehpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Ravidas Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Ravidas Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Ravidas Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Ravidas Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Ravidas Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Ravidas Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Ravidas Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Ravidas Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Ravidas Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Ravidas Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Ravidas Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Ravidas Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Ravidas Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221716,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221717,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 221718,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222181,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 222303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaunpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 223227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224176,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224181,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224183,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224186,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224188,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224189,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224189,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224190,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224195,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224195,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224284,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ambedkar Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 224284,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Faizabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 225416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barabanki",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 226501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lucknow",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amethi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227808,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227809,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227811,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227812,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227813,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227814,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227815,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227816,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 227817,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 228171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sultanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Allahabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raebareli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 229881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Unnao",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 230503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (UP)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonbhadra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 231501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mirzapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Varanasi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandauli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 232340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233300,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 233311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 241407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hardoi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 242407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahjahanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pilibhit",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pilibhit",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243631,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243632,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243633,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243634,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243635,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243636,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243637,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243638,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243639,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243641,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243720,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243723,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243724,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243725,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243726,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243727,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 243751,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Budaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244255,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jyotiba Phule Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rampur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 244713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 244713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 244714,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 244715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 244715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 244715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 244716,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 244717,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 244901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rampur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244921,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rampur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244922,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rampur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244923,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244923,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rampur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244924,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rampur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244925,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Moradabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244925,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rampur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244926,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rampur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244927,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rampur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 244928,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rampur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245101,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245101,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245201,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245201,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245205,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245205,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245207,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245207,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245208,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245208,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245301,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245301,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245304,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245304,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ghaziabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 245408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulaothi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246169,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246173,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246174,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246176,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246177,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246179,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246193,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246194,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246275,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246276,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246277,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246278,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246279,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246285,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246431,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246439,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246443,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246444,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246453,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246455,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246469,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246471,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246472,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246473,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246474,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246475,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246481,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246482,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246483,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246486,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246487,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246488,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamoli",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246495,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rudraprayag",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 246701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246721,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246723,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246724,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246725,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246726,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246727,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246728,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246729,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246731,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246732,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246733,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246734,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246735,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246736,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246737,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246745,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246746,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246747,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246749,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246761,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246762,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246763,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 246764,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijnor",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247342,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247343,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247451,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247453,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247656,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Roorkee",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 247661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Roorkee",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 247662,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247662,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haridwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 247663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haridwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 247664,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Roorkee",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 247665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Roorkee",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Roorkee",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 247666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Roorkee",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 247667,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Roorkee",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 247668,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Roorkee",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 247669,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharanpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247670,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 247670,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Roorkee",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 247671,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haridwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 247771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247772,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247773,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247774,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247775,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247776,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247777,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shamli",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 247778,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 248001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248179,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248195,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248196,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248197,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248198,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 248199,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttarkashi",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttarkashi",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttarkashi",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttarkashi",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttarkashi",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttarkashi",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttarkashi",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttarkashi",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249181,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249185,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttarkashi",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249186,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249192,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249193,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttarkashi",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249194,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttarkashi",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249195,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttarkashi",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249196,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249196,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttarkashi",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249199,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tehri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rishikesh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rishikesh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rishikesh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dehradun",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rishikesh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rishikesh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pauri Garhwal",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haridwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haridwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haridwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haridwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haridwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haridwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haridwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haridwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 249411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haridwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 250001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250342,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250344,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Meerut",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250609,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250617,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250619,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 250626,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagpat",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 251327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitapur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 261506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pilibhit",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pilibhit",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pilibhit",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pilibhit",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pilibhit",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pilibhit",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pilibhit",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pilibhit",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pilibhit",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champawat",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champawat",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champawat",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bareilly",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champawat",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champawat",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champawat",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champawat",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champawat",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262543,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262544,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262545,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262546,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262547,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262576,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pithoragarh",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262580,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champawat",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262580,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 262701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262721,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262723,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262724,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262725,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262726,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262727,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262728,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262904,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262905,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262906,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 262907,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 263001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haldwani",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udham Singh Nagar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nainital",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263619,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bageshwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263624,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263624,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bageshwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263626,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263628,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263628,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bageshwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263629,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bageshwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263631,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bageshwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263632,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bageshwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263633,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bageshwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263634,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bageshwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263635,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bageshwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263636,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263637,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263638,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263639,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263640,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bageshwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263641,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bageshwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263642,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bageshwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263643,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263645,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263646,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263652,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263653,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263655,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263656,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263658,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263659,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263664,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263667,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263676,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263678,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263679,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bageshwar",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 263680,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Almora",
      "State": "Uttarakhand",
      "State Code": 5
    },
    {
      "Pincode": 271001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gonda",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271609,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271821,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271821,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271824,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271825,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271830,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271831,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271835,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271840,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271841,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271845,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271845,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271851,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271855,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271855,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheri",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271861,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur (U P)",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271865,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271865,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271870,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271870,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271871,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271871,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271872,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271875,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271875,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shrawasti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271882,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 271904,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bahraich",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272173,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272176,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272177,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272178,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272181,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272189,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272190,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272191,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272191,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272192,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272193,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272194,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272194,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272195,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272199,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siddharthnagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272270,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272271,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sant Kabir Nagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 272302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Basti",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Maharajganj",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 273413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gorakhpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kushinagar",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 274808,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoria",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ghazipur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 275307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276288,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mau",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 276406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 277506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ballia",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hathras",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hathras",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hathras",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hathras",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hathras",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Azamgarh",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 281504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mathura",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 282001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 282002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 282003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 282004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 282005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 282006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 282007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 282008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 282009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 282010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Agra",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 283207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Firozabad",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lalitpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lalitpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lalitpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lalitpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lalitpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lalitpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lalitpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lalitpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lalitpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lalitpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lalitpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 284501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lalitpur",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhansi",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 285223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalaun",
      "State": "Uttar Pradesh",
      "State Code": 9
    },
    {
      "Pincode": 301001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 301714,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 302040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303348,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303904,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303905,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 303908,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 304804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tonk",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305624,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305627,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305628,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305629,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305811,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305812,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305813,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305814,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305815,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305816,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305817,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305819,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305921,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305922,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305922,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305923,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305924,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305925,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305925,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305926,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 305927,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ajmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 306912,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pali",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sirohi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 307803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 311806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilwara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pratapgarh (Rajasthan)",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312617,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312619,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 312901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313342,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajsamand",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313904,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313905,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 313906,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udaipur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 314804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dungarpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321608,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321609,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321633,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alwar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 321642,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharatpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dausa",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322249,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322252,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322254,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322255,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karauli",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 322704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sawai Madhopur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 323803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bundi",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 324001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 324002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 324003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 324004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 324005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 324006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 324007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 324008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 324009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 324010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baran",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 325602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittorgarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhalawar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 326530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kota",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 327801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banswara",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 328001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dholpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 328021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dholpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 328022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dholpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 328023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dholpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 328024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dholpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 328025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dholpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 328026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dholpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 328027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dholpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 328028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dholpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 328029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dholpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 328030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dholpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 328031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dholpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 328041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dholpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331811,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 331811,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332714,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332716,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332718,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332719,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332721,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332742,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sikar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 332746,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333053,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 333801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhujhunu",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 334808,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bikaner",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335061,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335062,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335063,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335064,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335065,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335073,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hanumangarh",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 335901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganganagar",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 341551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 342902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 343049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalor",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 344801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jodhpur",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 345034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaisalmer",
      "State": "Rajasthan",
      "State Code": 8
    },
    {
      "Pincode": 360001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360050,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jasdan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Jasdan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Jasdan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360053,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Jasdan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360056,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Jasdan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360058,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Jasdan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360063,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Jasdan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360065,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Jasdan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360067,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Jasdan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360070,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360075,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360077,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360375,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360380,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360460,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360470,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360480,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360480,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360490,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360490,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360490,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360490,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360545,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360560,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360570,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360575,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360576,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360577,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360578,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360579,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360590,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 360590,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361280,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dwarka",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361347,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 361350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362255,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362263,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362265,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362266,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362268,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362269,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362275,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362276,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Diu",
      "State": "Dadra and Nagar Haveli",
      "State Code": 26
    },
    {
      "Pincode": 362520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Diu",
      "State": "Daman and Diu",
      "State Code": 25
    },
    {
      "Pincode": 362530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Diu",
      "State": "Dadra and Nagar Haveli",
      "State Code": 26
    },
    {
      "Pincode": 362540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Diu",
      "State": "Daman and Diu",
      "State Code": 25
    },
    {
      "Pincode": 362550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362560,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362565,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362570,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Diu",
      "State": "Dadra and Nagar Haveli",
      "State Code": 26
    },
    {
      "Pincode": 362570,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Diu",
      "State": "Daman and Diu",
      "State Code": 25
    },
    {
      "Pincode": 362610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362640,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362650,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362650,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Porbandar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362720,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362725,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 362730,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363351,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363641,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Morbi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363642,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Morbi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363650,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363655,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 363670,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364050,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364070,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364081,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364265,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364270,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364275,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364280,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364290,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364295,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364465,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364470,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364485,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364490,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Botad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364720,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364730,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364740,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364750,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364760,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 364765,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhavnagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Junagadh",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365455,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365456,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365460,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365480,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365480,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajkot",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365535,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365545,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365560,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365565,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365635,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365640,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365645,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365650,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 365660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amreli",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhidham",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370455,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370460,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370465,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370475,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370485,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370490,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370627,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370640,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370645,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370650,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370655,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhuj",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370670,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 370675,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kutch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380001,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380002,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380004,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380005,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380006,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380007,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380008,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380009,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380013,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380014,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380015,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380016,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380018,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380019,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380021,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380022,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380023,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380024,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380026,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380027,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380028,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380050,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380051,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380052,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380054,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380055,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380058,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380059,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380060,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380060,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380061,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 380063,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382006,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382007,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382010,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382011,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382016,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382021,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382024,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382028,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382030,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382041,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382042,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382045,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382110,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382110,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382115,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382115,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382115,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382115,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382120,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382120,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382130,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382130,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382130,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382130,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382140,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382140,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382145,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382145,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382150,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382170,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382170,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382170,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382170,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382210,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382213,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382213,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382220,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382225,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382225,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382230,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382240,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382240,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382245,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382245,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382250,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382250,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382255,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382255,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382260,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382260,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382265,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382265,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382305,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382305,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382308,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382308,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382315,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382315,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382315,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382315,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382320,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382320,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382321,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382321,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382325,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382330,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382340,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382345,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382350,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382355,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382355,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382405,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382415,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382418,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382421,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382421,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382422,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382422,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382423,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382423,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382424,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382424,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382425,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382425,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382427,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382427,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382428,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382430,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382433,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382433,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382433,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382433,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382435,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382435,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382440,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382443,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382445,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382449,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382450,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382450,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382455,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382455,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382460,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382460,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382463,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382463,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382465,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382465,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382470,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382475,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382480,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382481,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382610,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382610,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382620,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382620,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382630,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382630,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382640,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382640,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382650,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382650,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382718,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382721,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382721,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382725,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382725,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382728,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382729,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382729,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382730,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382732,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382735,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382735,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382740,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382740,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382745,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382750,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382755,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382760,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382765,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382775,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382780,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surendra Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382810,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382810,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382815,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382820,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382825,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382830,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382835,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382835,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382840,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382845,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382845,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382850,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382855,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382855,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Gandhi Nagar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382860,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382865,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 382870,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383255,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383270,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383275,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383276,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Modasa",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383345,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383345,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383345,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383345,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383434,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383460,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 383462,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sabarkantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384255,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384265,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384272,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384275,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384285,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384290,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 384435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahesana",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palanpur",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patan",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385535,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385545,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385560,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385565,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385566,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385570,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 385575,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banaskantha",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387365,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387375,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387380,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387560,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387570,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387635,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387640,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387650,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 387810,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmedabad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388239,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388255,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balasinor",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388265,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388270,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388365,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388460,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388465,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388470,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388480,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388543,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388545,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388560,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388570,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388580,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388590,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388640,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anand",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388710,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388710,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kheda",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 388713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389190,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389190,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lunwada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389239,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lunwada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389265,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389365,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389380,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389380,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389382,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dahod",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 389390,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panch Mahals",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390001,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390002,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390003,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390004,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390005,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390006,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390007,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390008,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390009,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390010,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390011,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390012,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390013,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390014,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390015,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390016,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390017,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390018,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390019,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390020,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390021,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390022,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390023,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390024,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 390025,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391101,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391105,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391105,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391107,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391107,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391110,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391110,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391110,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391110,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391115,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391115,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391121,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391121,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391121,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391121,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391125,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391125,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391130,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391130,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391135,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391135,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391140,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391140,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391145,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391145,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391150,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391150,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391152,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391152,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391155,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391155,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391156,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391156,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391160,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391160,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391165,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chhotaudepur",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391165,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chhotaudepur",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391168,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391168,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391170,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391170,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391175,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391175,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391210,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391210,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391220,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391220,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391240,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391240,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391243,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391243,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391244,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391244,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391250,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391250,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391310,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391320,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391330,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391340,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391340,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391345,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391346,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391350,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391350,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391410,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391410,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391421,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391421,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391430,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391430,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391440,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391440,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391445,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391445,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391450,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391450,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391510,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391510,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391520,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391520,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391530,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391530,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391740,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391745,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391745,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391750,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391760,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391760,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391761,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391761,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391770,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391770,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391774,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391774,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391775,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391775,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391780,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391780,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391810,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 391810,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392012,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392012,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392012,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392012,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392015,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392015,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392015,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392015,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392030,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392030,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392030,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392030,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392110,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392110,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392110,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392110,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392130,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392130,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392130,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392130,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392140,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392140,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392140,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392140,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392150,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392150,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392150,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392150,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392150,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392150,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392150,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392150,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392160,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392160,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392160,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392160,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392180,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392180,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392180,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392180,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392210,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392210,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392210,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392210,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392220,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392220,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392220,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392220,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392310,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 392310,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ankleshwar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393002,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ankleshwar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393002,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ankleshwar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393010,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ankleshwar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393010,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ankleshwar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ankleshwar",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393041,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393041,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393041,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393041,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393041,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393041,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393050,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393050,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393050,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393050,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393050,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393050,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393105,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393105,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393105,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393105,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393125,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393125,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393125,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393125,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393125,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393125,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393130,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393130,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393130,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393130,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393130,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393130,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Vadodara",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 393155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394101,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394105,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394107,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394110,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394110,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394111,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394111,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394120,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394120,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394120,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394120,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394125,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394125,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394130,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394130,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394140,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394140,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394150,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394150,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394155,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394155,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394160,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394160,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394163,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394163,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394170,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394170,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394180,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394180,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394185,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394190,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394210,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394220,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394221,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394230,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394235,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394240,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394240,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394245,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394245,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394246,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394246,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394248,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394248,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394250,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394250,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394270,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394305,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394310,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394310,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394315,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394317,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394320,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394320,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394325,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394326,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394327,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394330,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394330,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394335,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394335,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394340,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394340,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394345,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394345,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394350,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394350,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394352,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394352,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394355,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394355,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394360,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394360,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394365,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394365,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394365,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394365,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394370,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394370,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394375,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394375,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394375,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394375,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394380,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394380,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394380,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394380,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394405,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394405,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394410,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394410,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394421,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394421,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394430,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394430,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394440,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394440,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394445,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394445,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394510,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394515,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394516,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394517,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394518,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394520,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394530,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394530,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394540,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394540,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394550,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394601,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394601,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394620,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394620,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394630,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394630,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394630,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394630,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394633,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394633,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394633,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394633,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394635,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394635,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394635,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394635,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394640,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394640,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394641,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394641,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394641,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394641,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394650,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394650,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394651,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394651,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394651,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394651,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394655,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394655,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394655,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394655,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394660,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394660,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394670,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394670,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394680,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394680,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394680,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394680,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tapi",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394690,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394690,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "The Dangs",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394715,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394715,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394715,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "The Dangs",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394715,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "The Dangs",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394716,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394716,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394716,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "The Dangs",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394716,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "The Dangs",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394720,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "The Dangs",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394730,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394730,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "The Dangs",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394810,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bharuch",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 394810,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narmada",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395001,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395002,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395003,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395004,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395005,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395006,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395007,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395008,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395009,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395010,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395011,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395012,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395017,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 395023,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396050,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396065,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396185,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396191,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396193,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dadra and Nagar Haveli",
      "State": "Dadra and Nagar Haveli",
      "State Code": 26
    },
    {
      "Pincode": 396193,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396195,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Daman",
      "State": "Dadra and Nagar Haveli",
      "State Code": 26
    },
    {
      "Pincode": 396210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Daman",
      "State": "Daman and Diu",
      "State Code": 25
    },
    {
      "Pincode": 396215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Daman",
      "State": "Dadra and Nagar Haveli",
      "State Code": 26
    },
    {
      "Pincode": 396215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Daman",
      "State": "Daman and Diu",
      "State Code": 25
    },
    {
      "Pincode": 396220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Daman",
      "State": "Dadra and Nagar Haveli",
      "State Code": 26
    },
    {
      "Pincode": 396220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Daman",
      "State": "Daman and Diu",
      "State Code": 25
    },
    {
      "Pincode": 396230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Silvassa",
      "State": "Dadra and Nagar Haveli",
      "State Code": 26
    },
    {
      "Pincode": 396230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Silvassa",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dadra and Nagar Haveli",
      "State": "Dadra and Nagar Haveli",
      "State Code": 26
    },
    {
      "Pincode": 396235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dadra and Nagar Haveli",
      "State": "Dadra and Nagar Haveli",
      "State Code": 26
    },
    {
      "Pincode": 396240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396375,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396380,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396385,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Valsad",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396433,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396436,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396439,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396460,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396463,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396466,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396469,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396472,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396475,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396510,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396510,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Surat",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396560,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396570,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396580,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 396590,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navsari",
      "State": "Gujarat",
      "State Code": 24
    },
    {
      "Pincode": 400001,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400001,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400002,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400003,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400004,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400005,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400006,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400007,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400008,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400009,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400010,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400011,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400012,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400013,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400014,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400015,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400016,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400017,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400018,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400019,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400020,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400021,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400022,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400023,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400024,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400025,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400026,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400027,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400028,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400029,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400030,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400031,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400032,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400033,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400034,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400035,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400036,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400037,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400038,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400039,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400040,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400042,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400043,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400045,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400046,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400047,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400048,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400049,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400050,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400051,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400052,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400053,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400054,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400055,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400056,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400057,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400058,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400059,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400060,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400061,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400062,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400063,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400064,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400065,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400066,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400067,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400068,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400069,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400070,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400071,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400072,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400073,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400074,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400075,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400076,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400077,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400078,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400079,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400080,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400081,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400082,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400083,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400084,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400085,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400086,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400087,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400088,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400089,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400090,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400091,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400092,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400093,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400094,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400095,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400096,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400097,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400098,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400099,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400101,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400102,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400103,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400104,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400105,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400601,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400602,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400603,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400604,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400605,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400606,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400607,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400608,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400609,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400610,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400612,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400613,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400614,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400615,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400701,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400702,
      "Zone": "A",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400703,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400704,
      "Zone": "A",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400705,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400706,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400707,
      "Zone": "A",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400708,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400709,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 400710,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401101,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401102,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401102,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401103,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401103,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401104,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401105,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401106,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401107,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401201,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401202,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401203,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401204,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401204,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401205,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401206,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401206,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401207,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401208,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401209,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401210,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401301,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401302,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401303,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401304,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401305,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasai Virar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401401,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401401,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401402,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401402,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401403,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401403,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401404,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401404,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401405,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401405,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401407,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401501,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401501,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401502,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401502,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401503,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401503,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401504,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401504,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401505,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401506,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401506,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Palghar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401601,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401601,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401602,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401602,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401603,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401603,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401604,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401604,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401605,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401605,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401606,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401606,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401607,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401607,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401608,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401608,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401609,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401609,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401610,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401610,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401701,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401701,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401702,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401702,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401703,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 401703,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 402503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "BICHOLIM",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panjim",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panjim",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panjim",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panjim",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panjim",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shiroda",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ponda",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sattari",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ponda",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ponda",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sanquelim",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ponda",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ponda",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ponda",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ponda",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Usgaon",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ponda",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharbandora",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bicholim",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bicholim",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pernem",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bicholim",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bicholim",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Goa",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sattari",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sattari",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mapusa",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saligao",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pernem",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pernem",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Candolim",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Calangute",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siolim",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiswadi",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pernem",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardez",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pernem",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bicholim",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sattari",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Margao",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Margao",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Margao",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Canacona",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Quepem",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sanguem",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Quepem",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Curchorem",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ponda",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Quepem",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Marmugao",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mormugao",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mormugao",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403714,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403716,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403717,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403718,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403719,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403720,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403721,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403723,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403724,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403725,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403726,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mormugao",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403728,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Canacona",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403729,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403731,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salcette",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mormugao",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasco-da-gama",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasco-da-gama",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasco-da-gama",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 403806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vasco-da-gama",
      "State": "Goa",
      "State Code": 30
    },
    {
      "Pincode": 410101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410206,
      "Zone": "A",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410208,
      "Zone": "A",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410210,
      "Zone": "A",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410217,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410218,
      "Zone": "A",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Navi Mumbai",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410301,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410301,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410302,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410302,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410401,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410401,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410402,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410402,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410403,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410403,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410405,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410405,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410406,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410406,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410501,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pimpri Chinchwad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410501,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pimpri Chinchwad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410502,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410502,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410503,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410503,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410504,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410504,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410505,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410505,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410506,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410506,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410507,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410507,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410508,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410508,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410509,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410509,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410510,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410510,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410511,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410511,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410512,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410512,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410513,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410513,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410515,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410515,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410516,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 410516,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411001,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411002,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411003,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411004,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411005,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411006,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411007,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411008,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411009,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411011,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411012,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411013,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411014,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411015,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411016,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411017,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pimpri Chinchwad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411018,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pimpri Chinchwad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411019,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pimpri Chinchwad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411020,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411021,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411022,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411023,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411023,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411024,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411025,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411025,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411026,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411027,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411028,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411030,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411031,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411032,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411033,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pimpri Chinchwad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411034,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411035,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411036,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411037,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411038,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411039,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411040,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411041,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411042,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411043,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411044,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pimpri Chinchwad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411045,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411046,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411047,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411048,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411051,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411052,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411053,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411057,
      "Zone": "A",
      "Effective Date": "1/1/2020",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411057,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "31/12/2019",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411057,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411058,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411060,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411061,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 411062,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pimpri Chinchwad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412101,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412102,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412102,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412103,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412103,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412104,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412104,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412105,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412106,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412106,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412107,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412107,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412108,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412108,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412109,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412109,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412110,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412112,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412112,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412114,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pimpri Chinchwad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412115,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412115,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412201,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412202,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412202,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412203,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412203,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412204,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412204,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412205,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412205,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412206,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412206,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412206,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412206,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412207,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412208,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412208,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412209,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412209,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412210,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412210,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412211,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412211,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412212,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412212,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412213,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412213,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412214,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412214,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412215,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412215,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412216,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412216,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412218,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412218,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412219,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412219,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412220,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412220,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412301,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412301,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412303,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412303,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412304,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412304,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412305,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412305,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412306,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412306,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412307,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412308,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412311,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412311,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412312,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412312,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412401,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412401,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412402,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412402,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412403,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412403,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412404,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412404,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412405,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412405,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412406,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412406,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412408,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412408,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412409,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412409,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412410,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412410,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412411,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412411,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412412,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412412,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Pune",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 412806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413102,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413102,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413103,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413103,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413104,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413104,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413105,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413105,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413106,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413106,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413110,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413110,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413114,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413114,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413115,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413115,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413116,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413116,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413120,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413120,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413130,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413130,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413132,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413132,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Indapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413133,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413133,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Baramati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413248,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413249,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413252,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413253,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413255,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Solapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413544,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413544,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413580,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413608,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413624,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413714,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413716,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413717,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413718,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413719,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413720,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413721,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413723,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413725,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413726,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413728,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413736,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413737,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413738,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413739,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413801,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413801,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413802,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 413802,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Daund",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414609,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 414701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh(MH)",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415536,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415537,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415538,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415539,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415608,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415609,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415617,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415619,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415626,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415628,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415629,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415634,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415637,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415639,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415640,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415641,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415643,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415714,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415716,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415717,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415718,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415719,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415720,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415724,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415726,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415727,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415728,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415729,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415730,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 415807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416420,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416436,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416437,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sangli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416549,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolhapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416608,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416609,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416626,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416628,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416632,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratnagiri",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416810,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416811,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416812,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 416813,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sindhudurg",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421001,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421002,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421003,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421004,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421005,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421101,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421102,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421103,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421201,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421202,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421203,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421204,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421205,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421206,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421301,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421302,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421303,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421303,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421304,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421305,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421306,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421308,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421311,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421312,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421312,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421401,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421401,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421402,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421402,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421403,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421403,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421501,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421502,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421503,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421504,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Badlapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421505,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421506,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421601,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421601,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421602,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421602,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421603,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421603,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Thane",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 421605,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalyan-Dombivali",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422608,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 422622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ahmed Nagar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 423703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nashik",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 424318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425420,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhule",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425444,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nandurbar",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 425524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalgaon",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hingoli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hingoli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hingoli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalna",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hingoli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Osmanabad",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Beed",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431536,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431537,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hingoli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hingoli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hingoli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hingoli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hingoli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431714,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431716,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431717,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431718,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431719,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431720,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Parbhani",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431721,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431723,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431731,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431736,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431741,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431742,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431743,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431745,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431746,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431750,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431808,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431809,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431810,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 431811,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nanded",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 440037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagpur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gondia",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gondia",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gondia",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gondia",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gondia",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gondia",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gondia",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441809,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gondia",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gondia",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441904,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441905,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441906,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441907,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441908,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441909,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441910,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441911,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gondia",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441912,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441913,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441914,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441915,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441916,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gondia",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 441924,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhandara",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "6/5/2020",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wardha",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadchiroli",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442904,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442905,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442906,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442907,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442908,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442914,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442916,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442917,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 442918,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandrapur",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 443404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Washim",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Washim",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Washim",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Washim",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buldhana",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Washim",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Washim",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Washim",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Washim",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Washim",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Washim",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Washim",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Washim",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Washim",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Akola",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444717,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444719,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444720,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444723,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444808,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444809,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444810,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444813,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444904,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444905,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444906,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444907,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 444908,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Amravati",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 445402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yavatmal",
      "State": "Maharashtra",
      "State Code": 27
    },
    {
      "Pincode": 450001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Burhanpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khandwa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khandwa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khandwa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Burhanpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Burhanpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Burhanpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khandwa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Burhanpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khandwa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khandwa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khandwa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khandwa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khandwa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 450991,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khandwa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khargone",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barwaha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barwaha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barwaha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khargone",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khargone",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barwani",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barwani",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barwani",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barwaha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barwani",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barwani",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barwani",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khargone",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Nimar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barwani",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451770,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barwani",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 451881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barwani",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 452020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 453771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Indore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454773,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454774,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 454775,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 455459,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dewas",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456443,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456664,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456668,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456770,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 456776,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ujjain",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ratlam",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457770,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457772,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457773,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457775,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457777,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457779,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457882,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457885,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alirajpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457885,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457887,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alirajpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457887,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457888,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alirajpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457888,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457893,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alirajpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457990,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 457993,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jhabua",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458389,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458468,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458470,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Neemuch",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458558,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458664,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458667,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458669,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458775,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458778,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458880,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458883,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458888,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458895,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 458990,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandsaur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460443,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460557,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 460668,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Harda",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Harda",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Harda",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Harda",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461668,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461775,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461990,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hoshangabad",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 461990,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462066,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 462120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 463106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 463111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vidisha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vidisha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vidisha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vidisha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vidisha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vidisha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vidisha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vidisha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vidisha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vidisha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464258,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vidisha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vidisha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vidisha",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464668,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464671,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464672,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464770,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464774,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464776,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464884,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464886,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464986,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464990,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464993,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhopal",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 464993,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raisen",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shajapur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465667,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465669,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465674,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465677,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465679,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465680,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465683,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465685,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465687,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465689,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465691,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465693,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465693,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 465697,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 466665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sehore",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Damoh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Damoh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470664,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Damoh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Damoh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470669,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470672,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Damoh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470673,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Damoh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470675,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Damoh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Damoh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470772,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Damoh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470775,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Damoh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470880,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Damoh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 470881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Damoh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 471625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhatarpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 472447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tikamgarh",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473249,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473287,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ashok Nagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ashok Nagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ashok Nagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ashok Nagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ashok Nagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473443,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ashok Nagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473443,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473444,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ashok Nagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ashok Nagar",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473585,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473638,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473662,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473670,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473770,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473774,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473775,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473781,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473793,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473865,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473880,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473885,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473885,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473990,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 473995,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shivpuri",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 474020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Datia",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Datia",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Datia",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475671,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Datia",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475673,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Datia",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475675,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Datia",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475682,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Datia",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475685,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Datia",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475686,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Datia",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 475686,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gwalior",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Morena",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Morena",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Morena",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Morena",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Morena",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Morena",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Morena",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Morena",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Morena",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheopur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheopur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheopur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheopur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheopur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476444,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Morena",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 476554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Morena",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheopur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477557,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477566,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 477660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhind",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Betul",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480557,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480559,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chhindwara",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480667,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480880,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480882,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480884,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480886,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480887,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480888,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480990,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480991,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480994,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480996,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480997,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 480999,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seoni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481662,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481664,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balaghat",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481668,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481672,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindori",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481672,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481768,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481776,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481778,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindori",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481879,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindori",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481880,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindori",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481882,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindori",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481884,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindori",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481885,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindori",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481885,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481990,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindori",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481995,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481996,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 481998,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandla",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 482001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 482002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 482003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 482004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 482005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 482008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 482009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 482010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 482011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 482020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 482021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 482051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 482056,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483053,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483770,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483773,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483775,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483880,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jabalpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483880,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 483990,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katni",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Umaria",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484444,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484444,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Umaria",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Umaria",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Umaria",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Umaria",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Umaria",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484664,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Umaria",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Umaria",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484669,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484770,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484770,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484774,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484776,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 484886,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Anuppur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485772,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485773,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485774,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485775,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485778,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 485881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Satna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486451,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rewa",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Umaria",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486669,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shahdol",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486669,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486670,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486675,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486775,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486776,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486882,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486884,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486885,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Singrauli",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486886,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486886,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Singrauli",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486887,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486887,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Singrauli",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486888,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Singrauli",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486889,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486889,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Singrauli",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486890,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 486892,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sidhi",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487770,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 487881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narsinghpur",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 488001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 488050,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 488051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 488058,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 488059,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 488220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 488222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 488333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 488441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 488442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 488443,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 488446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 488448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Panna",
      "State": "Madhya Pradesh",
      "State Code": 23
    },
    {
      "Pincode": 490001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 490006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 490009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 490011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 490012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhilai",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 490020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 490021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 490022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 490023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 490024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 490025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 490026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 490036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 490042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajnandgaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kawardha",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajnandgaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491444,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajnandgaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajnandgaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491557,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajnandgaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491558,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajnandgaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491559,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kawardha",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajnandgaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajnandgaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajnandgaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491668,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajnandgaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajnandgaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491885,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajnandgaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491888,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491888,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajnandgaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491993,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durg",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 491995,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kawardha",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492099,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahasamund",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 492112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493195,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493196,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493344,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahasamund",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahasamund",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahasamund",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahasamund",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahasamund",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493558,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahasamund",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493559,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493662,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhamtari",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhamtari",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493770,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhamtari",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493773,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhamtari",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493776,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhamtari",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493778,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhamtari",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493881,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493885,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493887,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gariaband",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493888,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493889,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493890,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493891,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gariaband",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493992,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 493996,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raipur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dantewada",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dantewada",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dantewada",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dantewada",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kondagaon",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanker",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanker",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanker",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanker",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanker",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494347,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dantewada",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bastar",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494444,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dantewada",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dantewada",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dantewada",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dantewada",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dantewada",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dantewada",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dantewada",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494635,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanker",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Narayanpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanker",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494669,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanker",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494670,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanker",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494771,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanker",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494776,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanker",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 494777,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanker",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495454,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495455,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495557,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495559,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495668,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bilaspur(CGH)",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495668,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495671,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495674,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495677,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495678,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495682,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495683,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495684,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495684,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495686,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495687,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495687,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495688,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495689,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495690,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495690,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495691,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495691,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Korba",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495692,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 495695,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Janjgir-champa",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 496665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raigarh",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jashpur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balrampur",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Surguja",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497451,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497557,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497559,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497773,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 497778,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koriya",
      "State": "Chhattisgarh",
      "State Code": 22
    },
    {
      "Pincode": 500001,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500002,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500003,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500004,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500005,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500006,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500007,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500008,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500009,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500010,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500011,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500012,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500013,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500014,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500015,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500016,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500017,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500018,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500019,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500020,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500022,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500023,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500024,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500025,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500026,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500027,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500028,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500029,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500030,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500031,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500032,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500033,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500034,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500035,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500036,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500037,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500038,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500039,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500040,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500041,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500042,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500043,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500044,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500045,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500046,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500047,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500048,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500049,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500050,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500051,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500052,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500053,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500054,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500055,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500056,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500057,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500058,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500059,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500060,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500061,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500062,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500063,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500064,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500065,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500066,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500067,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500067,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500068,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500068,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500069,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500070,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500070,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500072,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500073,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500074,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500075,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500075,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500076,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500076,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500077,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500077,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500078,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500079,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500079,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500080,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500081,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500082,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500083,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500083,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500084,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500084,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500085,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500086,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500086,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500087,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500088,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500088,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500089,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500089,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500090,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500090,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500091,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500092,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500092,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500093,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500094,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500095,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500096,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500097,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500097,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500098,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500098,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500100,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500101,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500101,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500108,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 500873,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501101,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501101,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501218,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501301,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501301,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501359,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501401,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hyderabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501401,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501505,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501510,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501511,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501512,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 501512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502032,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502248,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502249,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502255,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502256,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502257,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502267,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502269,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502270,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502271,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502273,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502276,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502277,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502278,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502279,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502280,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502281,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502285,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502286,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502287,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502290,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502291,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502293,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502294,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502295,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502296,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502300,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502300,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502324,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502325,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502371,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502372,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502375,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 502381,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medak",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503174,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503185,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503186,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503187,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503188,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 503322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nizamabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504272,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504273,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504292,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504293,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504294,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504295,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504296,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504297,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504299,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 504346,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Adilabad",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505002,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505002,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505174,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505184,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505185,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505186,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505187,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505188,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505451,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505453,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505454,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505455,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505460,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505462,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505466,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505467,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505468,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505469,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505470,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505471,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505472,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505473,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505474,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505475,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505476,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505480,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505481,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505490,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505497,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505498,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 505532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karim Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506169,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506244,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506252,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506342,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506343,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506344,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506347,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506348,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506349,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506352,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506356,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506365,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506366,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506367,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506368,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506369,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506371,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506381,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 506391,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Warangal",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 507133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507169,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507183,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 507318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khammam",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508244,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508248,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508252,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508253,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508254,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508255,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508256,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508257,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508258,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508266,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508277,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508278,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508279,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508280,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508284,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508285,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508286,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508373,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508374,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508376,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 508377,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalgonda",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509349,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509351,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509352,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509353,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509357,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509358,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509358,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509371,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509371,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509375,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509376,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509380,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509381,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509382,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509385,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangareddy",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 509412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahabub Nagar",
      "State": "Telangana",
      "State Code": 36
    },
    {
      "Pincode": 515001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515261,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515271,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515281,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515286,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515291,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515455,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515465,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515465,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515591,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515631,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515641,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515671,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515672,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515721,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515731,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515741,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515751,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515761,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515763,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515765,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515766,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515767,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515774,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515775,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515787,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515812,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515822,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515832,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515842,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515863,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515865,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515867,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515870,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515871,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 515872,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ananthapur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516173,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516193,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516257,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516259,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516267,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516268,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516269,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516270,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516289,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516293,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516349,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516356,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516359,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516361,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516362,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516380,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516390,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516391,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516396,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516431,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516433,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516434,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516439,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516444,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516454,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516464,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516474,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516484,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 516505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddapah",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517192,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517193,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517194,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517257,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517277,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517280,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517291,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517297,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517299,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517351,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517352,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517390,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517391,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517536,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517569,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517583,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517584,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517586,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517587,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517588,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517589,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517590,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517591,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517592,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517599,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517619,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517640,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517641,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517642,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517643,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517644,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 517645,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chittoor",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518176,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518186,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518196,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518343,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518344,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518346,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518347,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518348,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518349,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518380,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518385,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518390,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518395,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518396,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518462,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518463,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518464,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518465,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518466,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518467,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518468,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518543,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518563,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518573,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518583,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518593,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518594,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518598,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518599,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 518674,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurnool",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 520001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vijayawada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 520002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vijayawada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 520003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vijayawada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 520004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vijayawada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 520007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vijayawada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 520008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vijayawada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 520010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vijayawada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 520011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vijayawada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 520012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 520012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vijayawada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 520013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vijayawada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 520015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vijayawada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521178,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521181,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521183,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521185,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521190,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521256,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521261,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521263,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521286,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521343,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521344,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521356,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521366,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521369,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521390,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521456,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 521457,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishna",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522256,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522257,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522258,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522259,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522261,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522262,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522264,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522265,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522268,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522436,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522437,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522438,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522439,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522549,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522617,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522619,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522626,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522646,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522647,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522649,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522657,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522658,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522659,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 522663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guntur",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523169,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523181,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523183,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523184,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523185,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523186,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523187,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523190,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523252,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523253,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523254,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523261,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523262,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523263,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523264,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523265,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523270,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523271,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523272,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523273,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523274,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523279,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523280,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523281,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523286,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523291,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523292,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523346,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523356,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523357,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523367,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523368,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523369,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523371,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523372,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 523373,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Prakasam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524239,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524342,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524343,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524344,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524346,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524366,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 524421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nellore",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 530053,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531061,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531075,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531077,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531081,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531082,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531083,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531084,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531085,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531087,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531173,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 531219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532185,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532186,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532190,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532195,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532263,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532264,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532284,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532290,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532291,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532292,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532443,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532455,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532456,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532457,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532458,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532459,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532460,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532461,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532462,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532474,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 532484,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kakinada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kakinada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kakinada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kakinada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kakinada",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajamundry",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajamundry",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajamundry",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajamundry",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajamundry",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajamundry",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rajamundry",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533239,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533244,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533248,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533249,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533252,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533253,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533254,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533255,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533256,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533261,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533262,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533263,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533264,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533274,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533284,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533285,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533286,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533287,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533288,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533289,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533290,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533291,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533292,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533293,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533294,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533295,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533296,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533297,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533342,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533343,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533344,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533346,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533431,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533433,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533434,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533436,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533437,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533444,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533461,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533462,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533463,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thallarevu",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533464,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yanam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533464,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yanam",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 533468,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533483,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 533577,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Eluru",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Eluru",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Eluru",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Eluru",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Eluru",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Eluru",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534176,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534186,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534195,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534196,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534197,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534198,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534199,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534239,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534244,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534265,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534266,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534267,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534268,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534269,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534275,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534280,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534281,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534342,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534437,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534451,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534456,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534460,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534461,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534462,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534467,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 534475,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Godavari",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Srikakulam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535183,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535183,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535270,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535273,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Visakhapatnam",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535273,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535280,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535281,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535463,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535546,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535547,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535557,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535558,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535559,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535568,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535573,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535578,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535579,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535580,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535591,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535592,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535593,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 535594,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vizianagaram",
      "State": "Andhra Pradesh",
      "State Code": 37
    },
    {
      "Pincode": 560001,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560002,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560003,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560004,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560005,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560006,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560007,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560008,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560009,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560010,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560011,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560012,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560013,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560014,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560015,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560016,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560017,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560018,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560019,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560020,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560021,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560022,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560023,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560024,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560025,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560026,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560027,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560028,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560029,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560030,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560032,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560033,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560034,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560035,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560036,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560037,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560038,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560039,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560040,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560041,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560042,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560043,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560044,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560045,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560046,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560047,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560048,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560049,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560050,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560051,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560052,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560053,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560054,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560055,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560056,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560057,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560058,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560059,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560060,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560061,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560062,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560063,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560064,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560065,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560066,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560067,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560068,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560069,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560070,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560071,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560072,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560073,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560074,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560075,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560076,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560077,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560078,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560079,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560080,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560081,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560082,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560083,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560084,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560085,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560086,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560087,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560088,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560089,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560090,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560091,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560092,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560093,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560094,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560095,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560096,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560097,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560098,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560099,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560100,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560102,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560103,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560104,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560105,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560107,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560108,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560109,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560110,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560112,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 560300,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561101,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561101,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561201,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561201,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561201,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561201,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561203,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561203,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561203,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561203,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561204,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561204,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561205,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561205,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 561229,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562106,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562106,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562107,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562107,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562110,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562110,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562111,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562111,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562114,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562119,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562119,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562119,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562119,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562120,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562120,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562120,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562120,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562122,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562122,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562123,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562125,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562127,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562127,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562129,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562130,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562130,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562131,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562131,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562132,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562132,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562135,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562135,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562149,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562157,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562162,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562162,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562163,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 562163,
      "Zone": "C",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Bengaluru Rural",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chikkaballapur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563160,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 563162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 570030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571187,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571189,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571248,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571249,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571252,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571253,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571254,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571342,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571431,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571433,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571434,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571436,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571438,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571439,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571443,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571444,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571455,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571457,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571463,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571475,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571476,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571477,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571478,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571490,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chamrajnagar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571617,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mysore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571811,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 571812,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mandya",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 572228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tumkur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 573225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hassan",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574197,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574198,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574199,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574239,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574244,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574248,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574253,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574259,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574265,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574267,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574274,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574279,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574285,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kodagu",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 574509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dakshina Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 575030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangalore",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576257,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576282,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 576283,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Udupi",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577179,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577181,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577431,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577433,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577434,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577436,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577451,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577453,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Shimoga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577535,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577536,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577537,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577538,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577539,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577543,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577544,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577545,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577546,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577547,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577548,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577549,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chickmagalur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577557,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577557,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577558,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577566,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577589,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577597,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577598,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577599,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chitradurga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 577601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hubli",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hubli",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hubli",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hubli",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hubli",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hubli",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hubli",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hubli",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hubli",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hubli",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hubli",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 580118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581186,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581187,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581193,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581195,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581196,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581197,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haveri",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581342,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581343,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581344,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581346,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581347,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581348,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581349,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581351,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581352,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581353,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581354,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581356,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581357,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581358,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581359,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581361,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581362,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581363,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581365,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581384,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581396,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581400,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 581453,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Uttara Kannada",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharwad",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 582211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Davangere",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583239,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583268,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583268,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583275,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583276,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bellary",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583277,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583277,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583278,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583279,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583279,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583280,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583281,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583282,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gadag",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583282,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 583283,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koppal",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 584203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Raichur",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585265,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585287,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585290,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585291,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585292,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gulbarga",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585353,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Yadgir",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585436,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585437,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585443,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 585444,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bidar",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 586217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bijapur(KAR)",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 587330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bagalkot",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 590020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591173,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591239,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591244,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591248,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591254,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591263,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591265,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591287,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591344,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 591345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Belgaum",
      "State": "Karnataka",
      "State Code": 29
    },
    {
      "Pincode": 600001,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600002,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600003,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600004,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600005,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600006,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600007,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600008,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600009,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600010,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600011,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600012,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600013,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600014,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600015,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600016,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600017,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600018,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600019,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600020,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600021,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600022,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600023,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600024,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600025,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600026,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600027,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600028,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600029,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600030,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600031,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600032,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600033,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600034,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600035,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600036,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600037,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600038,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600039,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600040,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600041,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600041,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600042,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600043,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600044,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600045,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600046,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600047,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600048,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600049,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600050,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600050,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600051,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600052,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600053,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600053,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600054,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600054,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600055,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600056,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600056,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600057,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600057,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600058,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600058,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600059,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600059,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600060,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600061,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600061,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600062,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600062,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600063,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600063,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600064,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600064,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600065,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600065,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600066,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600066,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600067,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600067,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600068,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600068,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600069,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600069,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600070,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600070,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600071,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600071,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600072,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600072,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600073,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600073,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600074,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600074,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600075,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600075,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600076,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600076,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600077,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600077,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600078,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600079,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600080,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600081,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600082,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600083,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600084,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600085,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600086,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600087,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600087,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600088,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600088,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600089,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600089,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600090,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600091,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600091,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600092,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600093,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600094,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600095,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600095,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600096,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600096,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600097,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600097,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600098,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600098,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600099,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600099,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600100,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600101,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600102,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600103,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600104,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600105,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600106,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600107,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600107,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600108,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600109,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600110,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600111,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600112,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600113,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600114,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600115,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600116,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600117,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600118,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600119,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600120,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600122,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600123,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600124,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600125,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600126,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600127,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600128,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600128,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600129,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600130,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 600130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601101,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601102,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601102,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601103,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601301,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 601301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602001,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602002,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602003,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602021,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602024,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602025,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602101,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602103,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602105,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602107,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602109,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602117,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 602117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603001,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603002,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603003,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603101,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603102,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603103,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603104,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603105,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603108,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603109,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603110,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603111,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603112,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603127,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603202,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603203,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603204,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603209,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603210,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603211,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chennai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 603406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 604601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 605502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605652,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605751,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605752,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605754,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605755,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605756,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605757,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605758,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605759,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605766,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 605803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606751,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606752,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606753,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606754,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606755,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606808,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606811,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606904,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606905,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606906,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606907,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 606908,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 607402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Villupuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 607807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuddalore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 608901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karaikal",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 609602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karaikal",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 609603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karaikal",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 609603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karaikal",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 609604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karaikal",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 609606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karaikal",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 609607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karaikal",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 609608,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609609,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karaikal",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 609701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609808,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609810,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 609811,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 610207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 611112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 612904,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 613705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614617,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614618,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614619,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614624,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614626,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614628,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614629,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614714,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614716,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614716,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614717,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614723,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614738,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614808,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614809,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614810,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagapattinam",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvarur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614904,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614905,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 614906,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thanjavur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 620102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621652,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621653,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621653,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621714,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621715,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621716,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621717,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Perambalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621718,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621719,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621729,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621730,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 621851,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ariyalur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 622515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pudukkottai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623536,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623537,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623538,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623566,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623608,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 623806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624617,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624618,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624619,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 624802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625535,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625536,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625537,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625538,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625562,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625579,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Theni",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 625708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madurai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626188,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626189,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 626612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Virudhunagar",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627351,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627352,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627353,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627354,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627356,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627357,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627358,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627359,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627420,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627451,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627453,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627652,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627654,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627657,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627659,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627719,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627751,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627753,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627754,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627755,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627756,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627757,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627758,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627758,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627759,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627760,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627761,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627808,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627809,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627811,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627812,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627813,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627814,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627818,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627851,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627852,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627853,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627854,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627855,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627855,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627856,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627857,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627858,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627859,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627860,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627861,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627862,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627951,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 627953,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628252,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirunelveli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628617,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628618,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628619,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628653,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628656,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628714,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628716,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628718,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628720,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628721,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628751,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628752,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628753,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628809,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628851,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628904,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628905,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628906,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628907,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628908,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 628952,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuticorin",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629169,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629173,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629174,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629176,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629177,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629178,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629179,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629193,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629252,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629809,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629810,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629851,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629852,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 629902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanyakumari",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramanathapuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630557,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630558,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630559,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630562,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630566,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630609,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 630713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sivaganga",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvallur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kanchipuram",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 631702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632057,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632058,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632059,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 632604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hosur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hosur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hosur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hosur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hosur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hosur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hosur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnagiri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635652,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635653,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635654,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635655,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruvannamalai",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635751,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635752,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635754,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635808,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635809,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635810,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635811,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635812,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635813,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635814,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635815,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635851,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635852,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635853,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635854,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 635901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vellore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636351,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636352,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636354,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636451,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636453,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636454,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636455,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636456,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636457,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636458,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636807,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636808,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636809,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636810,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636811,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636812,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636813,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636902,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636903,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636904,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636905,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636906,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dharmapuri",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 636906,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Salem",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 637505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638053,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638054,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638056,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638057,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638181,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638183,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namakkal",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638451,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638453,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638454,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638455,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638456,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638457,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638458,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638459,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638460,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638461,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638462,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638476,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638656,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638657,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638660,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638672,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638673,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638751,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638752,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 638812,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruchirappalli",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 639207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641050,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641062,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruppur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruppur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruppur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruppur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruppur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruppur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruppur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641652,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tiruppur",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641653,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641654,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641655,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641658,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641659,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641662,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641664,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641667,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641668,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641669,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641670,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641671,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641687,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 641697,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dindigul",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Erode",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 642207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Coimbatore",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643239,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 643253,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nilgiris",
      "State": "Tamil Nadu",
      "State Code": 33
    },
    {
      "Pincode": 670001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thalassery",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thalassery",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thalassery",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thalassery",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thalassery",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670353,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670358,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670562,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670563,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670567,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670591,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670592,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670593,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670594,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670595,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670631,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670632,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670633,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670641,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thalassery",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670642,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thalassery",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670643,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670644,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670645,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670646,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670649,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thalassery",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670650,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670662,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670671,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670672,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670673,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670674,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670675,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670676,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670691,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670692,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670693,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670694,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670721,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670731,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 670741,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671348,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671351,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671543,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 671552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kasargod",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mahe",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 673310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pondicherry",
      "State": "Pondicherry",
      "State Code": 34
    },
    {
      "Pincode": 673311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kannur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673570,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673572,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673573,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673574,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673575,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673576,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673577,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673579,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673580,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673585,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673586,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673591,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673592,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673593,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673595,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673596,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wayanad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673631,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673632,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673632,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673633,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673633,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673634,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673635,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673636,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673637,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673638,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673639,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673640,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673641,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673642,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673645,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673647,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673655,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 673661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kozhikode",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 676562,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678543,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678544,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678545,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678546,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678557,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678572,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678573,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678574,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678583,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678591,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678592,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678593,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678594,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678595,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678596,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678597,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678598,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678624,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678631,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678632,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678633,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678641,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678642,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678671,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678681,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678682,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678683,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678684,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678685,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678686,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678687,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678688,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678721,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678731,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678732,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 678762,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679351,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "PERINTHALMANNA",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679357,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679535,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679536,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palakkad",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679562,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679563,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679564,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679572,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679573,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679574,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679575,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679576,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679577,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679578,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679579,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679580,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679583,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679584,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679585,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679586,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679587,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 679591,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malappuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680543,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680544,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680545,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680546,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680562,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680563,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680564,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680565,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680566,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680567,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680568,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680569,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680570,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680583,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680584,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680585,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680586,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680587,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680588,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680589,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680590,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680591,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680594,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680596,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680617,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680618,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680619,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680631,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680641,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680642,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680652,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680653,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680654,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680655,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680656,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680662,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680664,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680667,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680667,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680668,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680669,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680670,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680671,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680672,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680681,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680682,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680683,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680684,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680685,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680686,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680687,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680688,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680689,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680691,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680697,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680699,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680721,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680724,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680731,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680732,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680733,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680734,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680741,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 680751,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thrissur",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kochi",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kochi",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kochi",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kochi",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kochi",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kochi",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kochi",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kochi",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kochi",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kochi",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 682551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakshadweep",
      "State": "Lakshadweep",
      "State Code": 31
    },
    {
      "Pincode": 682552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakshadweep",
      "State": "Lakshadweep",
      "State Code": 31
    },
    {
      "Pincode": 682553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakshadweep",
      "State": "Lakshadweep",
      "State Code": 31
    },
    {
      "Pincode": 682554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakshadweep",
      "State": "Lakshadweep",
      "State Code": 31
    },
    {
      "Pincode": 682555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakshadweep",
      "State": "Lakshadweep",
      "State Code": 31
    },
    {
      "Pincode": 682556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakshadweep",
      "State": "Lakshadweep",
      "State Code": 31
    },
    {
      "Pincode": 682557,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakshadweep",
      "State": "Lakshadweep",
      "State Code": 31
    },
    {
      "Pincode": 682558,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakshadweep",
      "State": "Lakshadweep",
      "State Code": 31
    },
    {
      "Pincode": 682559,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakshadweep",
      "State": "Lakshadweep",
      "State Code": 31
    },
    {
      "Pincode": 683101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kochi",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683543,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683544,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683545,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683546,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683547,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683548,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683549,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683562,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683563,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683565,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683572,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683573,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683574,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683575,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683576,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683577,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683578,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683579,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683580,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683585,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683587,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683589,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 683594,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "THIRUVANANTHAPURAM",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685535,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685562,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685563,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685565,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685566,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685583,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685584,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685585,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685586,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685587,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685588,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685589,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685590,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685591,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685595,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685608,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thodupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685609,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685618,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685619,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 685620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Idukki",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686535,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686536,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686537,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686538,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686539,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686543,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686544,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686545,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686546,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686547,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686548,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686560,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686562,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686563,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686564,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686572,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686573,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686574,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686575,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686576,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686577,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686578,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686579,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686580,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686583,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686584,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686585,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686586,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686587,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686608,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686609,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686631,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686632,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686633,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686634,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686635,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686636,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686637,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686652,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686653,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kottayam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muvattupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686662,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muvattupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muvattupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686664,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muvattupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muvattupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muvattupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686667,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muvattupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686668,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muvattupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686669,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muvattupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686670,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muvattupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686671,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686672,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muvattupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686673,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muvattupuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686681,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686691,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686692,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 686693,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ernakulam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688535,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688536,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688537,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688538,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688539,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688562,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688570,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 688582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689543,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689544,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689545,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689546,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689547,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689548,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689549,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689550,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689572,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689573,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689574,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689583,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689584,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689585,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689586,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689587,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689588,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689589,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689590,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689591,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689592,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689594,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689595,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689597,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689624,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689626,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689627,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689641,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689642,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689643,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689644,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689645,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689646,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689647,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689648,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689649,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689650,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689652,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689653,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689654,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689656,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689662,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689664,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689667,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689668,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689671,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689672,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689673,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689674,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689675,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689676,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689677,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689678,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689691,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689692,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689693,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689694,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689695,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689695,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689696,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689698,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689699,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 689711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690518,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690535,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690536,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690537,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690538,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690539,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690544,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690547,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690547,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690548,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690558,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690559,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690572,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alappuzha",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690573,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 690574,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691500,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691511,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691530,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691531,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691532,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691533,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691534,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691535,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691536,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691537,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691538,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691540,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691543,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691552,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691553,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691554,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691555,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691556,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pathanamthitta",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691557,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691559,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691560,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691566,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691572,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691573,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691574,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691576,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691577,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691578,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691579,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691583,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691584,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691585,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691589,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691590,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 691602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kollam",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695541,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695542,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695543,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695547,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695551,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695561,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695562,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695563,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695564,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695568,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695572,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695573,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695574,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695575,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695582,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695583,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695584,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695585,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695586,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695587,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695588,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695589,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695605,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695608,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695609,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 695615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thiruvananthapuram",
      "State": "Kerala",
      "State Code": 32
    },
    {
      "Pincode": 700001,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700002,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700003,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700004,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700005,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700006,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700007,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700008,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700009,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700010,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700011,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700012,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700013,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700014,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700015,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700016,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700017,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700018,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700019,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700020,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700021,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700022,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700023,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700024,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700025,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700026,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700027,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700028,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700029,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700030,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700031,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700032,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700033,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700034,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700035,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700036,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700037,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700038,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700039,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700040,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700041,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700042,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700043,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700044,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700045,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700046,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700047,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700048,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700049,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700050,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700051,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700052,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700053,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700054,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700055,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700056,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700056,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700057,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700057,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700058,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700058,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700059,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700059,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700060,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700061,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700062,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700063,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700064,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700064,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700065,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700066,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700067,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700068,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700069,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700070,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700070,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700071,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700072,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700073,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700074,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700075,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700076,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700076,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700077,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700078,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700079,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700079,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700080,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700081,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700081,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700082,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700083,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700083,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700084,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700084,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700085,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700086,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700087,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700088,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700089,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700089,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700090,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700091,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700091,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700092,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700093,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700093,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700094,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700095,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700096,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700096,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700097,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700097,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700098,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700098,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700099,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700100,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700101,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700102,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700103,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700104,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700105,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700105,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700106,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700107,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700108,
      "Zone": "B",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700109,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700110,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700111,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700112,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700113,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700114,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700115,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700116,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700117,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700118,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700119,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700120,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700121,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700122,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700123,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700124,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700125,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700126,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700127,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700128,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700129,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700130,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700131,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700132,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700133,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700134,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700135,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700135,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700136,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700137,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700138,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700139,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700140,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700141,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700142,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700143,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700144,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700145,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700146,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700147,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700148,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700149,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700150,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700151,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700152,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700153,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700154,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700155,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700156,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700157,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700158,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700159,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 700159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711101,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711102,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711103,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711104,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711105,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711106,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711107,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711108,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711109,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711110,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711111,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711112,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711113,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711114,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711201,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711202,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711203,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711204,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711205,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711225,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711227,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711304,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711305,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711306,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711307,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711308,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711309,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711310,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711313,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711315,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711316,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711317,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711322,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagadishpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711403,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711409,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 711414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712101,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712102,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712103,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712104,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712105,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712121,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712123,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712124,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712125,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712136,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712137,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712138,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712139,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712201,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712202,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712203,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712204,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712222,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712223,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712232,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712233,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712234,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712235,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712245,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712246,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712247,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712248,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712248,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712249,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712249,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712250,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712258,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712258,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712310,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712311,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Howrah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712502,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712503,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712515,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712617,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712706,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712707,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712708,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 712708,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Hooghly",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713169,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713173,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Durgapur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713342,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713343,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713344,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713346,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713347,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713357,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713358,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713359,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713361,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713362,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713363,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713365,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713367,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713368,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713369,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713371,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713372,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713373,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713375,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713376,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713377,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713378,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713380,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713381,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713383,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713384,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713385,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713386,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713420,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713433,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713434,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713512,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713519,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 713520,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bardhaman",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Medinipur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721253,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721420,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721431,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kharagpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721431,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721433,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721434,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721436,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721437,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721438,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721439,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721443,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721444,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721451,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721453,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721454,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721455,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721456,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721457,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721458,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721463,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721467,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721515,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721516,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721517,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721607,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721626,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721627,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721628,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721629,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721631,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721632,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721633,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721634,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721635,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721636,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721641,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721642,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721643,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721644,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721645,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721646,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721647,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721648,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721649,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721650,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721651,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721652,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721653,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721654,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721655,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721656,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721657,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721658,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 721659,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Midnapore",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722169,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722173,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722174,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722176,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722177,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722178,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722179,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722181,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722183,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 722209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bankura",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 723215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puruliya",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731244,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 731303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Birbhum",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 732216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malda",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 733215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Dinajpur",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siliguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siliguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siliguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siliguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siliguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siliguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalimpong",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalimpong",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalimpong",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalimpong",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalimpong",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalimpong",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalimpong",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalimpong",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalimpong",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalimpong",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalimpong",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siliguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalimpong",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siliguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siliguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siliguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siliguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734431,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734434,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734436,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 734503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "JAIGAON",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darjeeling",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 735305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alipurduar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Alipurduar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736169,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736176,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736179,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cooch Behar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 736208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jalpaiguri",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 737101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gangtok",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gangtok",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gangtok",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pakyong",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dikchu",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gayzing",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pelling",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangan",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangan",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangan",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mangan",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chungthang",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorethang",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Namchi",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Melli",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangli",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangpo",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rhenock",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Singtam",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gangtok",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranipool",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rangpo",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Singtam",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 737139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ravangla",
      "State": "Sikkim",
      "State Code": 11
    },
    {
      "Pincode": 741101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnanagar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnanagar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Krishnanagar",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741181,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranaghat",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chakdah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chakdah",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741234,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741235,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Kalyani",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Kalyani",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741245,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Haringhata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741248,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741249,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741250,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741251,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741252,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741252,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741253,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741254,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741255,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741256,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741257,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Phulia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 741509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nadia",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742174,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742184,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742187,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742189,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 742408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Murshidabad",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743122,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743123,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743124,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743125,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743127,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743128,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743129,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743130,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743133,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743134,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743135,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743136,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743144,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743145,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743165,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743166,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743193,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743193,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743194,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743194,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Falta",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743247,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743248,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743248,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743249,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743252,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743262,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743263,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743268,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743270,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743271,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743272,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743273,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743286,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743287,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743289,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743290,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743291,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743291,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743292,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743293,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743294,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743297,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743330,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743347,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743348,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743349,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743351,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743353,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolkata",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743354,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743356,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743357,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743363,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743368,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Diamond Harbour",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743371,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743372,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743373,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743374,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743375,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743376,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743377,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743378,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743383,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743384,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743385,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743387,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743389,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743390,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743391,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743392,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743395,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743396,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743398,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743399,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743437,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743438,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743439,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743456,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743502,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743606,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743609,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743613,
      "Zone": "B",
      "Effective Date": "15/12/2020",
      "Expiry Date": "1/1/2999",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "14/12/2020",
      "City": "South 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 743711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North 24 Parganas",
      "State": "West Bengal",
      "State Code": 19
    },
    {
      "Pincode": 744101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Port Blair",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Port Blair",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Port Blair",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Port Blair",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Port Blair",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Port Blair",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Port Blair",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Port Blair",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Diglipur",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Diglipur",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Andaman",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Andaman",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Andaman",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Andaman",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Andaman",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North And Middle Andaman",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Port Blair",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Port Blair",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nicobar",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nicobar",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nicobar",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 744304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nicobar",
      "State": "Andaman and Nicobar Islands",
      "State Code": 35
    },
    {
      "Pincode": 751001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 751031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752050,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752054,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752056,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752057,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752061,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752062,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752063,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752064,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752065,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752066,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752068,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752069,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752070,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752077,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752078,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752079,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752080,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752081,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752082,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752083,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752084,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752085,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752089,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752090,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752091,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752092,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752093,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752094,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nayagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 752121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Puri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhubaneswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 753015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khorda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754071,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754082,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754200,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754239,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754244,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754246,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754248,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754253,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754289,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754290,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754292,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754293,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754294,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jagatsinghapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754295,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cuttack",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 754296,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755050,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755061,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendrapara",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 755062,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756056,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756058,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756059,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756079,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756080,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756081,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756083,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756084,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756085,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756086,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756181,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhadrak",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 756182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Baleswar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757050,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757053,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757054,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757073,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757074,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757075,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757077,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757079,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757081,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757082,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757083,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757084,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757085,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757086,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757087,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757091,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757092,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757093,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 757107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mayurbhanj",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jajapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758076,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758078,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758079,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758080,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758081,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758082,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758083,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758084,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 758085,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kendujhar",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhenkanal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 759148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Angul",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 760001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 760002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 760003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 760004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 760005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 760006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 760007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 760008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 760009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 760010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761054,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761200,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ganjam",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 761217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gajapati",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Boudh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Boudh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Boudh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Boudh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Boudh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Boudh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Boudh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Boudh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Boudh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Boudh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Boudh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Boudh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 762112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kandhamal",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 763001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 763002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 763003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 763004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 763008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malkangiri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malkangiri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malkangiri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malkangiri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malkangiri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764047,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malkangiri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malkangiri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malkangiri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malkangiri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764056,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764057,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764058,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764059,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764059,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764061,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764062,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764063,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764070,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764071,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764072,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764073,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764074,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764075,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764076,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764077,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764078,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764081,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764085,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764086,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malkangiri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764087,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Malkangiri",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 764088,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koraput",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nabarangapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 765034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rayagada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nuapada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nuapada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nuapada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nuapada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nuapada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nuapada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kalahandi",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 766118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nuapada",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767061,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767062,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767065,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767066,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767067,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767068,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767068,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonapur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 767070,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Balangir",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768049,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768050,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Debagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Debagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Debagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Debagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bargarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Debagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Debagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768200,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Debagarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sambalpur",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 768234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jharsuguda",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rourkela",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 769043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770041,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770042,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770043,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770044,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770048,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770070,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770072,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770073,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770074,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770075,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 770076,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sundergarh",
      "State": "Odisha",
      "State Code": 21
    },
    {
      "Pincode": 781001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781036,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781037,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781038,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781039,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781040,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Guwahati",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781341,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781343,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781344,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781346,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781347,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781348,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781349,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781351,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781352,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781353,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781354,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781364,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781365,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781366,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781367,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Barpeta",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781367,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781368,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781369,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781371,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781372,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781373,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781374,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781375,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781376,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781377,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781378,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalbari",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781380,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781381,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 781382,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Marigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Marigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Marigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Marigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Marigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Marigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Marigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Marigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kamrup",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Marigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Marigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Marigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Marigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782439,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782445,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782447,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782448,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782460,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782460,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782461,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782462,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782470,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782480,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782481,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782481,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782482,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782485,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782486,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 782490,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karbi Anglong",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 783124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Goalpara",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 783135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783345,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783346,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783347,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783348,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783349,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783350,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783354,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783361,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783369,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783371,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783372,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783373,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783375,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783376,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kokrajhar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783380,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783381,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783382,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783383,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783383,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783384,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783385,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783388,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783388,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhubri",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783389,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783390,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783391,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783392,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783393,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 783394,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bongaigaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784169,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784172,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784173,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784174,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784175,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784176,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784177,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784178,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784179,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784180,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784184,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784189,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784190,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sonitpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784510,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784513,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784514,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784521,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784522,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784523,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784524,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784525,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784526,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784527,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784528,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 784529,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darrang",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nagaon",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785609,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785618,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785619,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785626,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785630,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785631,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785632,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785633,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785633,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785634,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785635,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785636,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785640,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785661,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785662,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785663,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785664,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785665,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785666,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785667,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785669,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785670,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785671,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785671,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785672,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785673,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785674,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785675,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785676,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785680,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785681,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785682,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785683,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785684,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785685,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785686,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785687,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785688,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785689,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785690,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785691,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785691,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785692,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785693,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785694,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785696,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785697,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785698,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785699,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785700,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785702,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785703,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785704,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jorhat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Golaghat",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 785705,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sibsagar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786170,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786171,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786173,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786174,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786179,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786181,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786182,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786183,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786183,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786184,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786187,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786188,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786189,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786189,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786190,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786191,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786192,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tinsukia",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786610,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 786692,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibrugarh",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhemaji",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787032,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787033,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhemaji",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787034,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhemaji",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787051,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787052,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787053,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhemaji",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787053,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787054,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787056,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhimpur",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787057,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhemaji",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787058,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhemaji",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787059,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhemaji",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787060,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhemaji",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787061,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhemaji",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 787110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhemaji",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788030,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788031,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788098,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788099,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Cachar Hills",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Cachar Hills",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788168,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788709,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788711,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788712,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788713,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788719,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788720,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788722,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788723,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788724,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788725,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788726,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788727,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788728,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788733,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788734,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788735,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788736,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788737,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788781,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788801,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788802,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hailakandi",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788803,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788804,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788805,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788806,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Karimganj",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788815,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788816,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788817,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Cachar",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788818,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Cachar Hills",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788819,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Cachar Hills",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788820,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Cachar Hills",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788830,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Cachar Hills",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788831,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Cachar Hills",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788832,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Cachar Hills",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 788931,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Cachar Hills",
      "State": "Assam",
      "State Code": 18
    },
    {
      "Pincode": 790001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Papum Pare",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 790001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Kameng",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 790002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Kameng",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 790003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Kameng",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 790101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Kameng",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 790102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Kameng",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 790102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Kameng",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 790103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Kameng",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 790104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tawang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 790105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tawang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 790106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tawang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 790114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Kameng",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 790116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Kameng",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Papum Pare",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Siang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Upper Siang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Siang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Siang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Siang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Upper Siang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Siang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Siang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Upper Siang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Papum Pare",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Papum Pare",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Papum Pare",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Papum Pare",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lower Subansiri",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Papum Pare",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kurung Kumey",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lower Subansiri",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Papum Pare",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lower Subansiri",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Papum Pare",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Upper Subansiri",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Papum Pare",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 791125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Siang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lohit",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792055,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Changlang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792056,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Changlang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibang Valley",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lohit",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Changlang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lohit",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lohit",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Papum Pare",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lohit",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dibang Valley",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lower Dibang Valley",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lohit",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Changlang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Changlang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Changlang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Changlang",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirap",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirap",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 792131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tirap",
      "State": "Arunachal Pradesh",
      "State Code": 12
    },
    {
      "Pincode": 793001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ri Bhoi",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ri Bhoi",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ri Bhoi",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ri Bhoi",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ri Bhoi",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaintia Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South West Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Khasi Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ri Bhoi",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ri Bhoi",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaintia Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaintia Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaintia Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793200,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaintia Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 793210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jaintia Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 794115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South West Garo Hills",
      "State": "Meghalaya",
      "State Code": 17
    },
    {
      "Pincode": 795001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal West",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churachandpur",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Senapati",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Senapati",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandel",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thoubal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandel",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thoubal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Senapati",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Senapati",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Senapati",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Senapati",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churachandpur",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bishnupur",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churachandpur",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Senapati",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Senapati",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bishnupur",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Senapati",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tamenglong",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bishnupur",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandel",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churachandpur",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Senapati",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thoubal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandel",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bishnupur",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bishnupur",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chandel",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thoubal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thoubal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churachandpur",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795140,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tamenglong",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ukhrul",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Churachandpur",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ukhrul",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ukhrul",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Imphal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tamenglong",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thoubal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Thoubal",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Senapati",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 795159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tamenglong",
      "State": "Manipur",
      "State Code": 14
    },
    {
      "Pincode": 796001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796070,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796070,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolasib",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796081,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolasib",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796082,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolasib",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796091,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolasib",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kolasib",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796181,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Serchhip",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796184,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Serchhip",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796186,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Serchhip",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796190,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796261,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796290,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champhai",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champhai",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champhai",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champhai",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Champhai",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796370,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Serchhip",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mammit",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mammit",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796431,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mammit",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mammit",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796471,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aizawl",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796471,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mammit",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mammit",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796571,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lunglei",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796581,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lunglei",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796691,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lunglei",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796701,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lunglei",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796710,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lunglei",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796730,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lunglei",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796751,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lunglei",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796770,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lawngtlai",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796770,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lunglei",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796891,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lawngtlai",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lawngtlai",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 796901,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saiha",
      "State": "Mizoram",
      "State Code": 15
    },
    {
      "Pincode": 797001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kohima",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kohima",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kohima",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kohima",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kohima",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kohima",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797099,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wokha",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797100,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wokha",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kohima",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Peren",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Phek",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dimapur",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Phek",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kohima",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dimapur",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Phek",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Phek",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kohima",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Zunhebotto",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Peren",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Wokha",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dimapur",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dimapur",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Phek",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dimapur",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dimapur",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dimapur",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kohima",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 797121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kohima",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798601,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mokokchung",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798602,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mon",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798603,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mon",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798604,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mokokchung",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798611,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kiphire",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798612,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuensang",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798613,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mokokchung",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798614,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mokokchung",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798615,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mokokchung",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798616,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuensang",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798617,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mokokchung",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798618,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mokokchung",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798619,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Zunhebotto",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798620,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Zunhebotto",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798621,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mon",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798622,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mon",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798623,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Mokokchung",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798625,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Longleng",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798626,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Tuensang",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 798627,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Zunhebotto",
      "State": "Nagaland",
      "State Code": 13
    },
    {
      "Pincode": 799001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799035,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799045,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799046,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "South Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhalai",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799250,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799251,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799253,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799254,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799256,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799260,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799261,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799262,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799263,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799264,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799266,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhalai",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799266,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799269,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799270,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799271,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799273,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhalai",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799273,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799275,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhalai",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799277,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799278,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhalai",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799279,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799280,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799281,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799284,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhalai",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799285,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhalai",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799286,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhalai",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799287,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhalai",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799288,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799289,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhalai",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 799290,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "North Tripura",
      "State": "Tripura",
      "State Code": 16
    },
    {
      "Pincode": 800001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800021,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800022,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800023,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800024,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800025,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800026,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800027,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800028,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 800029,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 801507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaimur (Bhabua)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802136,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802159,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802162,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802163,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802164,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802183,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaimur (Bhabua)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaimur (Bhabua)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Buxar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802351,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 802352,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 803307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Arwal",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Arwal",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Arwal",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804420,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Arwal",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804434,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804451,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804453,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 804454,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nawada",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 805236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheikhpura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheikhpura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheikhpura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Nalanda",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheikhpura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhisarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhisarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheikhpura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhisarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhisarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheikhpura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamui",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhisarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Patna",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamui",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhisarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheikhpura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamui",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamui",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamui",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhisarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhisarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamui",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhisarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamui",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamui",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamui",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamui",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lakhisarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheikhpura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamui",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 811317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamui",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 812001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 812002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 812003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 812004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 812005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 812006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 812007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Godda",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 813208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sahibganj",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 813209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 813222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 814101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Godda",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pakur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Banka",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 814133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Godda",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pakur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814141,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814142,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814145,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Godda",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814148,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814151,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Godda",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814152,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814153,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Godda",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814154,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Godda",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814155,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Godda",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814156,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Godda",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814157,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814158,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Godda",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814165,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Godda",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814166,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamtara",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 814167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815351,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamtara",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815352,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamtara",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815353,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815354,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamtara",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Godda",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815355,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamtara",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815357,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Deoghar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 815359,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamtara",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sahibganj",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Godda",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pakur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sahibganj",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pakur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pakur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pakur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sahibganj",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pakur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pakur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sahibganj",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sahibganj",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sahibganj",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sahibganj",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sahibganj",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Pakur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dumka",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sahibganj",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 816129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sahibganj",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 821101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaimur (Bhabua)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaimur (Bhabua)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaimur (Bhabua)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaimur (Bhabua)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaimur (Bhabua)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaimur (Bhabua)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaimur (Bhabua)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaimur (Bhabua)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kaimur (Bhabua)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821310,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 821312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Rohtas",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 822101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latehar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Garhwa",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latehar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Garhwa",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Garhwa",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latehar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Garhwa",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Garhwa",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Garhwa",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Garhwa",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Garhwa",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Garhwa",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 822134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Garhwa",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 823001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 823002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 823003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 823004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 823005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 823311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Arwal",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Arwal",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jehanabad",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gaya",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 824303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Aurangabad(BH)",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 825101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chatra",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koderma",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koderma",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825167,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koderma",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chatra",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koderma",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chatra",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chatra",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chatra",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koderma",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chatra",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koderma",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koderma",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koderma",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Giridh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koderma",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 825421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Koderma",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 826001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 826003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 826004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 826005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 827001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 827003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 827004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 827006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 827009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 827010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 827011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 827012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 827013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 827014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 827302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 827302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828309,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Dhanbad",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 828404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Hazaribag",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829144,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829149,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829150,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ramgarh",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Chatra",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Garhwa",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latehar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Palamau",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latehar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latehar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latehar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Latehar",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 829301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bokaro",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831012,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831013,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831014,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831015,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831016,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831017,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831018,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831019,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 831020,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela-kharsawan",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela-kharsawan",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela-kharsawan",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela-kharsawan",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Jamshedpur",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela-kharsawan",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 832404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela-kharsawan",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela-kharsawan",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela-kharsawan",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela-kharsawan",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela-kharsawan",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela-kharsawan",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela-kharsawan",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Seraikela-kharsawan",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 833223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 834001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 834002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 834003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 834004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 834005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 834006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 834008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 834009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 834010,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 834011,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Simdega",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Simdega",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Simdega",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lohardaga",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Singhbhum",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lohardaga",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Simdega",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Simdega",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Simdega",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khunti",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Simdega",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khunti",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lohardaga",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gumla",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Lohardaga",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 835325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Ranchi",
      "State": "Jharkhand",
      "State Code": 20
    },
    {
      "Pincode": 841101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841237,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841239,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841241,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841242,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841243,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841244,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841245,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841286,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841287,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhojpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841420,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841434,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841436,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841437,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841438,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841439,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841441,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841442,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841443,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841446,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841460,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Gopalganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 841509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Siwan",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 842001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 842002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 842003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 842004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 842005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843143,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843146,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843147,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheohar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843313,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843314,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheohar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheohar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843319,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843320,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843321,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843322,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843323,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843324,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheohar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheohar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sheohar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843351,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 843360,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844119,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844507,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844508,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 844509,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Vaishali",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845404,
      "Zone": "A",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845406,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845412,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845413,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845414,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845415,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845416,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845417,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845418,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845419,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845420,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845425,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845426,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845430,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845431,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845432,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845433,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845434,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845435,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845436,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845437,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845438,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845440,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845449,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845450,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845451,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845453,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845454,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845455,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845456,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845457,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845458,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "East Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 845459,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "West Champaran",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 846001,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 846002,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 846003,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 846004,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 846005,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 846006,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 846007,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 846008,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 846009,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847222,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847223,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847224,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847225,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847226,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847227,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847228,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847229,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847230,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847231,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847232,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847233,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847234,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847235,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847238,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847239,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847239,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847240,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Muzaffarpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847307,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Sitamarhi",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847308,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847401,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847402,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847403,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847404,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847405,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847407,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847408,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847409,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847410,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847411,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847421,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847422,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847423,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847424,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847427,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847428,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847429,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847451,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhubani",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 847452,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848160,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848206,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848207,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848208,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848209,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848236,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848501,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848502,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848503,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848504,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848505,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 848506,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Samastipur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851118,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851120,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851133,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851134,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851135,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851210,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851211,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 851218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Begusarai",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852110,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852111,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852121,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852122,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852123,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852124,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852125,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852126,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852127,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852128,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852129,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852130,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852131,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852132,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852137,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852138,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852139,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852161,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852212,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852213,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852214,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852215,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Darbhanga",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852216,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852217,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852218,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852219,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852220,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 852221,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Saharsa",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 853201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 853202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 853203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 853203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Khagaria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 853204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 853204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 853204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Madhepura",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 853204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Munger",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 853204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 853205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Bhagalpur",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854103,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854104,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854109,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854112,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854201,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854202,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854203,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854204,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854205,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854301,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854302,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854303,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854304,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854305,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854306,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854311,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854312,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854315,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854316,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854317,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854318,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854325,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854326,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854327,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854328,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854329,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854330,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854331,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854332,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854333,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854334,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854335,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854336,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Araria",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854337,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Purnia",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854338,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854339,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 854340,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Supaul",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 855101,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kishanganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 855102,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 855105,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 855106,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kishanganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 855107,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kishanganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 855108,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kishanganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 855113,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 855114,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Katihar",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 855115,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kishanganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 855116,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kishanganj",
      "State": "Bihar",
      "State Code": 10
    },
    {
      "Pincode": 855117,
      "Zone": "C",
      "Effective Date": "31/12/1899",
      "Expiry Date": "1/1/2999",
      "City": "Kishanganj",
      "State": "Bihar",
      "State Code": 10
    }
  ]