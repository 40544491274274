import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { FormHandlerService } from '../form-handler.service';
import { ToastrService } from 'ngx-toastr';
import { constant } from '../constant';
declare var $: any;



@Component({
  selector: 'app-investment',
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.css']
})
export class InvestmentComponent implements OnInit {

  term: any = {};
  formData: any = {}; // Define formData to store form data
  phoneNumber: string = ''; // Define phoneNumber to store the phone number
  planRidersData: any[] = [];

  constructor(
    private formHandlerService: FormHandlerService,
    private toastr: ToastrService,
    private http: HttpClient
  ) { }


  ngOnInit() {
    this.loadInvestmentPlanData();
    $("html, body").animate({ scrollTop: 0 }, 600);
  }

  submit() {
    var regex = constant.emailvalidateregex;
    var mobilevalidateregex = constant.mobilevalidateregex;
    if (!this.term.term_user_name) {
      this.toastr.error("Please enter your name", "Error");
    } else if (!this.term.term_user_email) {
      this.toastr.error("Please enter your valid email", "Error");
    } else if (!regex.test(this.term.term_user_email)) {
      this.toastr.error("Please enter a valid email address", "Error");
    } else if (!mobilevalidateregex.test(this.term.term_user_mobile)) {
      this.toastr.error("Please enter your valid mobile number", "Error");
    } else {
      this.formData = {
        "name": this.term.term_user_name,
        "phoneNumber": this.term.term_user_mobile,
        "email": this.term.term_user_email
      }

      this.formHandlerService.handleFormSubmission(this.formData, 'Investment Plan');
      }
  }

  loadInvestmentPlanData() {
    this.http
      .get("assets/companyjson/accordionData.json")
      .subscribe((res: any) => {
        this.planRidersData = res.investmentPlanRiders;
      });
  }

}
