const icici_medical_questions = [
    {
      "question_title": " Hypertension: High blood pressure history.",
      "question_desc": "",
      "DiseaseID": "13",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Diabetes Mellitus: Sugar history.",
      "question_desc": "",
      "DiseaseID": "14",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Hyperlipidemia: Cholesterol history.",
      "question_desc": "",
      "DiseaseID": "15",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Smoking and Alcohol Consumption",
      "question_desc": "Does any person proposed to be insured smoke or consume tobacco in any form or alcohol? If yes, please indicate the quantity consumed. If not, please indicate No.",
      "DiseaseID": "16",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Heart and Circulatory Conditions or Disorders",
      "question_desc": "Chest pain, angina, palpitations, congestive heart failure, coronary artery disease, heart attack, bypass surgery, angioplasty, valve disorder, replacement, pacemaker insertion, rheumatic fever, congenital heart condition, varicose veins, clots in veins or arteries, blood disorders, anticoagulant therapy, etc.",
      "DiseaseID": "17",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Urinary Conditions or Disorders",
      "question_desc": "Blood in urine, increase in urinary frequency, painful or difficult urination, kidney and/or bladder infections, stones of urinary system, kidney failure, dialysis, or any other kidney, urinary tract, or prostate disease.",
      "DiseaseID": "18",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Musculoskeletal Conditions or Disorders",
      "question_desc": "Joint or back pain, arthritis, spondylosis, spondylitis, spinal disorders, surgeries, osteoporosis, osteomyelitis, joint replacement, or any other disorder of muscle, bone, joint, ligaments, tendons, or discs, gout, herniated disc, fractures or accidents, implants, amputation, or prosthesis, muscle weakness, polio, etc.",
      "DiseaseID": "19",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Respiratory Conditions or Disorders",
      "question_desc": "Shortness of breath, asthma, emphysema, tuberculosis, chronic obstructive pulmonary disease (COPD), respiratory failure, surgeries, chronic cough, or any other respiratory system disorders or lung disease, sleep apnea, or any other disorder of ear, nose, or throat, or other ENT disorders.",
      "DiseaseID": "20",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Gastrointestinal Conditions or Disorders",
      "question_desc": "Gastritis, ulcer, reflux, inflammatory bowel disease, irritable bowel syndrome (IBS), Crohn's disease, ulcerative colitis, colitis, gall bladder disease, stones or removal, pancreatic disorder, hernia, fistula, piles, hemorrhoids, or any other gastrointestinal system disorders.",
      "DiseaseID": "21",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Liver Conditions or Disorders",
      "question_desc": "Jaundice, cirrhosis, fatty liver, alcoholic liver disease, hepatitis A, hepatitis B, hepatitis C, or any other liver conditions or disorders.",
      "DiseaseID": "22",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Eye Conditions or Disorders",
      "question_desc": "Blindness, partial vision, cataract, glaucoma, or any other eye condition or eye disorders.",
      "DiseaseID": "23",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Neurological Conditions or Disorders",
      "question_desc": "Stroke, TIA (transient ischemic attack), paralysis, seizures, epilepsy, multiple sclerosis, Parkinson's disease, muscular dystrophy, cerebral palsy, nervous system disorders, Alzheimer's disease, dementia, or any other brain or nervous system conditions or disorders.",
      "DiseaseID": "24",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Endocrine Conditions or Disorders",
      "question_desc": "Thyroid disorder, hyperthyroidism, hypothyroidism, Cushing's syndrome, Addison disease, pheochromocytoma, or any other endocrine system conditions or disorders.",
      "DiseaseID": "25",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Psychiatric Mental Illness",
      "question_desc": "Anxiety, depression, schizophrenia, bipolar disorder, obsessive-compulsive disorder (OCD), post-traumatic stress disorder (PTSD), psychiatric treatment, counseling, therapy, psychiatric hospitalization?",
      "DiseaseID": "26",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Cancer",
      "question_desc": "Malignancy, tumor growth, leukemia, lymphoma, myeloma, Hodgkin's disease, or any other cancerous disease and related surgeries, etc.?",
      "DiseaseID": "27",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Pregnancy and Infertility",
      "question_desc": "Is any female member pregnant, tested positive with a home pregnancy test, or undergoing infertility treatment for ectopic pregnancy?",
      "DiseaseID": "28",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Chronic or Long-term Medical Condition",
      "question_desc": "Does the person proposed to be insured suffer from any chronic or long-term medical condition, or have any other disability, abnormality, recurrent illness, or injury, or is unable to perform normal activities?",
      "DiseaseID": "29",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Other Medical Consultations or Treatments",
      "question_desc": "Has any member consulted with or received treatment from any doctor or other health care provider for any other condition or symptoms, undergone any hospitalization or illness or surgery, or currently taking medications for any condition or medical procedures, including diagnostic testing?",
      "DiseaseID": "30",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Family History of Disease",
      "question_desc": "Does the individual have a family history of any disease like heart disease, brain disease, cancer, organ failure, autoimmune disorder, or genetic disorder?",
      "DiseaseID": "31",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Insurance Application History",
      "question_desc": "Has any application for life, health, hospital daily cash, or critical illness insurance ever been declined, postponed, loaded, or been made subject to any special conditions by any insurance company?",
      "DiseaseID": "32",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    },
    {
      "question_title": " Pending Medical Advice or Treatment",
      "question_desc": "Have you or any other member proposed to be insured under this policy sought medical advice or been advised or awaiting any treatment, medical or surgical, due to any of the diseases/conditions listed above or otherwise attend follow-up for any diseases/conditions/ailment/injury/addiction?",
      "DiseaseID": "33",
      "existing_since": {
        "mm": "",
        "yy": ""
      },
      "TreatmentTaken": ""
    }
  ];
  
export { icici_medical_questions };
