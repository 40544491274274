const icici_add_ons = [
    {
      "addon_title": "Critical Illness Health Plan",
      "addon_desc": "We will pay You or Your Nominee / legal heir the Annual Sum Insured subject to a maximum limit of Rs.50 Lakhs in case You are diagnosed as suffering from one or more of the Critical Illnesses for the first time in your life, during the Policy Period. However, We will not make any payment if You are first diagnosed as suffering from a Critical Illness within 90 days of the Period of Insurance Start Date.This add on/ Optional Cover can be claimed by You only once during Your lifetime. No Claim under this Optional Cover shall be admissible in case any of the Critical Illnesses is a consequence of or arises out of any Pre - Existing Condition(s) / Disease.",
      "addon_id": "78",
    "addon_conditions": [
      "age > 18",
      "age <= 65",
      "selectedDiseaseIds.length == 0",
    ],
    "showMembers": false,
    },
  {
    "addon_title": "Maternity cover",
      "addon_desc": "This optional benefit covers the medical expenses up to 10% of the Annual Sum Insured; subject to a maximum limit of INR 1 Lakhs (Sum Insured) for the delivery of a baby and / or expenses related to medically recommended lawful termination of pregnancy but only in life threatening situation under the advice of Medical Practitioner, limited to maximum of three deliveries or terminations as said herein during the lifetime of an female Insured/Insured Person as the case may be between the ages of 18 years to 45 years in the Policy.",
      "addon_id": "79",
      "test": true,
      "addon_conditions": [
        "age>18",
        "age <= 45",
        "gender=='FEMALE'"
      ],
      "showMembers": false,
      "child_cover": [
        {
          "addon_title": "New Born Baby Cover",
            "addon_desc": "We will cover the Medical Expenses incurred by the Insured Person on Hospitalization of a 'New born Baby' during each Policy Year of Policy Period subject to the maximum limit of twice of the maternity sum Insured. This limit is over and above the maternity sum insured. This Optional Cover will cover Medical Expenses incurred on the “New born Baby” during Hospitalization (for a minimum period of consecutive 24 hours) for a maximum period up to 90 days from the date of birth of the baby",
            "addon_id": "80",
            "test": true,
            "addon_conditions": [
              "age > 18",
              "age <= 45",
              "gender=='FEMALE'"
            ],
            "showMembers": false,
        },
        {
          "addon_title": "Vaccination for New Born Baby",
            "addon_desc": "We will cover the expenses incurred on Vaccinations of the new born baby till one year of age during the policy period up to 1% of the Sum Insured subject to a maximum limit of INR 10,000. This limit is over and above the Maternity Sum Insured.",
            "addon_id": "81",
            "test": true,
            "addon_conditions": [
              "age > 18",
              "age <= 45",
              "gender=='FEMALE'"
            ],
            "showMembers": false,
        },
      ]
  },

  {
    "addon_title": "Personal Accident Cover",
      "addon_desc": "We will pay You or Your Nominee / legal heir, as the case may be, the Annual Sum Insured subject to a maximum limit of Rs.50 Lakhs , on occurrence of any Insured Event, as specifically described hereunder, arising due to an Injury sustained by You during the Policy Year. This cover is available only for adult members aged maximum up to 65 years during first time issuance. a. Accidental Death We shall pay 100% of the coverage amount of the Insured / Insured Person, in the event of his / her Death on account of an Accident / Injury, during the Policy Period or within twelve calendar months from the date of occurrence of such Accident / Injury which occurred during Policy Period.    b. Permanent Total Disablement We shall pay up to the coverage amount of the Insured Person as specified below in case of his / her permanent total disablement on account of any Accident / Injury, during the Policy Period or within twelve calendar months from the date of occurrence of such Accident / Injury which occurred during Policy Period.",
      "addon_id": "82",
      "addon_conditions": [
        "age > 18",
        "age <= 65",
      ],
      "showMembers": false,
  },
  {
    "addon_title": "Sum Insured Protector",
      "addon_desc": "The Sum Insured Protector Cover is designed to protect the Annual Sum Insured against rising inflation by linking the Annual Sum Insured under the Basic Cover to the Consumer Price index (CPI). The Annual Sum Insured will be increased on cumulative basis at each Renewal on the basis of inflation rate in previous year. Inflation rate would be computed as the average CPI of the entire calendar year published by the Central Statistical Organisation (CSO).The % increase will be applicable only on Annual Sum Insured under the Policy and not on Guaranteed Cumulative Bonus or any other Covers which leads to increase in Sum Insured. At the time of renewal, if the insured person opts out of the optional cover, then the sum insured under the sum Insured Protector cover accrued upto the expiring policy year will be forfeited.",
      "addon_id": "83",
      "addon_conditions": [
        "age > 18",
      ],
      "showMembers": false,
  },
  {
    "addon_title": "Claim Protector",
      "addon_desc": "If a claim has been accepted under the “Inpatient Treatment” or “Daycare Procedure/Treaments” Cover, the items which are included in the List I- Items for which coverage is not available in the Policy of Annexure II, which are non – payable, to the particular claim, will become payable. i. The maximum claim pay out under this add on/Optional Cover shall be limited to Sum Insured under the Policy.    ii. Any Sum Insured accrued under Guaranteed Cumulative bonus/Sum Insured Protector/Reset benefit will not be available for Claim Protector Cover.",
      "addon_id": "84",
      "addon_conditions": [
        "age > 18",
      ],
      "showMembers": false,
  },
  {
    "addon_title": "Compassionate Visit",
      "addon_desc": "In event of Your Hospitalization, which in the opinion of the Medical Practitioner attending on You, extends beyond a period of 5 consecutive days, We will indemnify the cost of the economy class air ticket/railway ticket incurred by Your Immediate Family Member from and to the place of origin of such Family Member or the place of residence of the Family Member. Our liability under this Optional Cover, however, in respect of any one event or all events of Hospitalization during the Policy Year shall not in aggregate exceed Rs. 20,000 per Policy Year of Policy Period.",
      "addon_id": "85",
      "addon_conditions": [
        "age > 18",
      ],
      "showMembers": false,
  },
  {
    "addon_title": "Nursing at Home",
      "addon_desc": "We will pay You for the expenses incurred by You, up to Rs. 2,000 per day up to a maximum of 10 days post Hospitalization for the medical services of a Qualified Nurse at Your residence, provided that the nurse is employed in a Hospital and the engagement of such Qualified Nurse is certified as necessary by a Medical Practitioner and relate directly to any Illness or Injury, covered under the Policy.",
      "addon_id": "86",
      "addon_conditions": [
        "age > 18",
      ],
      "showMembers": false,
  },
  {
    "addon_title": "Befit A",
      "addon_desc": "Unlock the essentials with Befit A! Enjoy one outpatient consultation and a comprehensive routine diagnostics cover of Rs. 500. Additionally, you'll benefit from a pharmacy cover of Rs. 500. Plus, take advantage of six e-counseling and diet and nutrition e-consultation sessions each.",
      "addon_id": "87",
      "addon_conditions": [
        "age > 18",
       ],
      "showMembers": false,
  },
  {
    "addon_title": "Befit B",
      "addon_desc": "Experience enhanced care with Befit B! Get two outpatient consultations and Rs. 1,000 coverage for routine diagnostics and minor procedures. Benefit from Rs. 1,000 in pharmacy cover. Plus, access six e-counseling and diet and nutrition e-consultation sessions each, and enjoy six physiotherapy sessions for added wellness.",
      "addon_id": "88",
      "addon_conditions": [
        "age > 18",
      ],
      "showMembers": false,
  },
  {
    "addon_title": "Befit C",
      "addon_desc": "Maximize your health with Befit C! Avail four outpatient consultations and Rs. 1,000 cover for routine diagnostics and minor procedures. Enjoy Rs. 1,000 in pharmacy cover. Plus, receive six physiotherapy sessions, along with six e-counseling and diet and nutrition e-consultation sessions each.",
      "addon_id": "89",
      "addon_conditions": [
        "age > 18",
        
      ],
      "showMembers": false,
  },
  {
    "addon_title": "Befit D",
      "addon_desc": "Upgrade your health package with Befit D! Benefit from six outpatient consultations and Rs. 2,000 coverage for routine diagnostics and minor procedures. Get Rs. 2,000 in pharmacy cover. Plus, enjoy eight physiotherapy sessions, and six e-counseling and diet and nutrition e-consultation sessions each.",
      "addon_id": "90",
      "addon_conditions": [
        "age > 18",
        
      ],
      "showMembers": false,
  },
  {
    "addon_title": "Befit E",
      "addon_desc": "Opt for premium care with Befit E! Access eight outpatient consultations and Rs. 3,000 cover for routine diagnostics and minor procedures. Avail Rs. 3,000 in pharmacy cover. Plus, receive ten physiotherapy sessions, and twelve e-counseling and diet and nutrition e-consultation sessions each.",
      "addon_id": "91",
      "addon_conditions": [
        "age > 18",
        
      ],
      "showMembers": false,
  },
  {
    "addon_title": "Befit F",
      "addon_desc": "Indulge in comprehensive coverage with Befit F! Enjoy ten outpatient consultations and Rs. 5,000 coverage for routine diagnostics and minor procedures. Benefit from Rs. 5,000 in pharmacy cover. Plus, receive twelve physiotherapy sessions, and unlimited e-counseling and diet and nutrition e-consultation sessions.",
      "addon_id": "92",
      "test": true,
      "addon_conditions": [
        "age > 18",
        
      ],
      "showMembers": false,
  },
  {
    "addon_title": "Befit G",
      "addon_desc": "Experience the ultimate health package with Befit G! Avail twelve outpatient consultations and Rs. 7,500 coverage for routine diagnostics and minor procedures. Enjoy Rs. 7,500 in pharmacy cover. Plus, benefit from twelve physiotherapy sessions, and unlimited e-counseling and diet and nutrition e-consultation sessions.",
      "addon_id": "93",
      "test": true,
      "addon_conditions": [
        "age > 18",
        
      ],
      "showMembers": false,
  },
];

const befitAddonIds = ['87', '88', '89', '90', '91', '92', '93', '94'];
  
export {icici_add_ons, befitAddonIds};  