export const careAdvantageAddOn = [
    {
      "addon_title": "Pre and Post Hospitalization",
      "addon_id": "PREPHECA1150",
    "showMembers": false,
},
    {
      "addon_title": "Unlimited E-Consultation",
      "addon_id": "UECV1153",
    "showMembers": false,
},
    {
      "addon_title": "Smart Select",
      "addon_id": "SMARTCA",
    "showMembers": false,
},
    {
      "addon_title": "NCB-Super",
      "addon_id": "CAREADWITHNCB",
    "showMembers": false,
},
    {
      "addon_title": "Room Rent Modification",
      "addon_id": "RRMCA",
    "showMembers": false,
},
    {
      "addon_title": "Air Ambulance Cover",
      "addon_id": "AACCA1090",
    "showMembers": false,
},
    {
      "addon_title": "Ext of Global Coverage(Exc US)",
      "addon_id": "NEGCE4092",
    "showMembers": false,
},
    {
      "addon_title": "Ext of Global Coverage(Inc US)",
      "addon_id": "NEGCI4093",
    "showMembers": false,
},
    {
      "addon_title": "Reduction in PED",
      "addon_id": "RIPEDCA1092",
    "showMembers": false,
},
    {
      "addon_title": "Care Shield",
      "addon_id": "CARESHILED1104",
    "showMembers": false,
},
    {
      "addon_title": "Care OPD",
      "addon_id": "COPD1211",
    "showMembers": false,
},
    {
      "addon_title": "Annual Health Check-up",
      "addon_id": "HCUPCA1093",
    "showMembers": false,
}
  ]

export const careFreedomAddOn =[
    {
      "addon_title": "Health Check Plus",
      "addon_id": "CFHP",
    "showMembers": false,
},
    {
      "addon_title": "Deductible",
      "addon_id": "CAREFREEDOMDEDUCTIBLERIDER-25000",
    "showMembers": false,
},
    {
      "addon_title": "Home Care",
      "addon_id": "CFWHC",
    "showMembers": false,
},
    {
      "addon_title": "OPD Care(Mandatory)",
      "addon_id": "COPD1211",
    "showMembers": false,
}
  ]

  export const careHeartAddOn = [
    {
      "addon_title": "Care Shield",
      "addon_id": "CARESHILEDCF1209",
    "showMembers": false,
},
    {
      "addon_title": "Home Care",
      "addon_id": "CFWHC",
    "showMembers": false,
},
    {
      "addon_title": "OPD Care(Mandatory Add-on)",
      "addon_id": "COPD1211",
    "showMembers": false,
}
  ]