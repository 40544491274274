export const careAdvantageQuestion = [
    {
      "question_set_code": "PEDdiabetesDetails",
      "question_code": 205,
      "question_title": "Diabetes?",
      "type": "Question",
      "PED New Description": "Diabetes Mellitus type 1 or Diabetes on insulin or Diabetes associated with blindness or chronic foot ulcer"
    },
    {
      "question_set_code": "PEDdiabetesDetails",
      "type": "subquestion",
      "question_code": "diabetesExistingSince",
      "Question description": "Existing since? (MM/YYYY)",
      "PED New Description": "Existing since? (MM/YYYY)"
    },
    {
      "question_set_code": "PEDhyperTensionDetails",
      "type": "Question",
      "question_code": 207,
      "question_title": "Hypertension / High Blood Pressure",
      "PED New Description": "Hypertension / High Blood Pressure"
    },
    {
      "question_set_code": "PEDhyperTensionDetails",
      "type": "subquestion",
      "question_code": "hyperTensionExistingSince",
      "Question description": "Existing since? (MM/YYYY)",
      "PED New Description": "Existing since? (MM/YYYY)"
    },
    {
      "question_set_code": "PEDliverDetails",
      "type": "Question",
      "question_code": 232,
      "question_title": "Liver Disease?",
      "PED New Description": "Pancreatitis or Liver disease (including but not limited to Cirrhosis / Hepatitis B or C / Willson’s disease) or any other digestive track disorder (disorders of esophagus or stomach or intestine or any other)"
    },
    {
      "question_set_code": "PEDliverDetails",
      "type": "subquestion",
      "question_code": "liverExistingSince",
      "Question description": "Existing since? (MM/YYYY)",
      "PED New Description": "Existing since? (MM/YYYY)"
    },
    {
      "question_set_code": "PEDcancerDetails",
      "type": "Question",
      "question_code": 114,
      "question_title": "Cancer?",
      "PED New Description": "Cancer"
    },
    {
      "question_set_code": "PEDcancerDetails",
      "type": "subquestion",
      "question_code": "cancerExistingSince",
      "Question description": "Existing since? (MM/YYYY)",
      "PED New Description": "Existing since? (MM/YYYY)"
    },
    {
      "question_set_code": "PEDcardiacDetails",
      "type": "Question",
      "question_code": 143,
      "question_title": "Cardiac Disease?",
      "PED New Description": "Any cardiovascular/Heart Disease (including but not limited to Coronary artery disease / Rheumatic heart disease / Heart Attack or Myocardial infarction / Heart failure / Bypass Grafting or CABG / Angioplasty or PTCA / Heart valve diseases / Pacemaker implantation)"
    },
    {
      "question_set_code": "PEDcardiacDetails",
      "type": "subquestion",
      "question_code": "cardiacExistingSince",
      "Question description": "Existing since? (MM/YYYY)",
      "PED New Description": "Existing since? (MM/YYYY)"
    },
    {
      "question_set_code": "PEDjointpainDetails",
      "type": "Question",
      "question_code": 105,
      "question_title": "Joint Pain?",
      "PED New Description": "Any disorders of Blood and / or Immunity (including but not limited to bleeding or clotting disorders, Systemic Lupus Erythematosus, Rheumatoid Arthritis, Crohn’s disease, Ulcerative Colitis)."
    },
    {
      "question_set_code": "PEDjointpainDetails",
      "type": "subquestion",
      "question_code": "jointpainExistingSince",
      "Question description": "Existing since? (MM/YYYY)",
      "PED New Description": "Existing since? (MM/YYYY)"
    },
    {
      "question_set_code": "PEDkidneyDetails",
      "type": "Question",
      "question_code": 129,
      "question_title": "Kidney Disease?",
      "PED New Description": "Any Kidney / urinary track / reproductive organ Disease"
    },
    {
      "question_set_code": "PEDkidneyDetails",
      "type": "subquestion",
      "question_code": "kidneyExistingSince",
      "Question description": "Existing since? (MM/YYYY)",
      "PED New Description": "Existing since? (MM/YYYY)"
    },
    {
      "question_set_code": "PEDparalysisDetails",
      "type": "Question",
      "question_code": 164,
      "question_title": "Paralysis?",
      "PED New Description": "Any Neuromuscular (muscles or nervous system) disorder or  Psychiatric disorders (including but not limited to Motor Neuron Disease, Muscular dystrophies, Epilepsy, Paralysis, Parkinsonism, multiple sclerosis, stroke, mental illness)"
    },
    {
      "question_set_code": "PEDparalysisDetails",
      "type": "subquestion",
      "question_code": "paralysisExistingSince",
      "Question description": "Existing since? (MM/YYYY)",
      "PED New Description": "Existing since? (MM/YYYY)"
    },
    {
      "question_set_code": "PEDcongenitalDetails",
      "type": "Question",
      "question_code": 122,
      "question_title": "Congenital Disorder?",
      "PED New Description": "Congenital Disorder?"
    },
    {
      "question_set_code": "PEDcongenitalDetails",
      "type": "subquestion",
      "question_code": "congenitalExistingSince",
      "Question description": "Existing since? (MM/YYYY)",
      "PED New Description": "Existing since? (MM/YYYY)"
    },
    {
      "question_set_code": "PEDHivaidsDetails",
      "type": "Question",
      "question_code": 147,
      "question_title": "HIV/AIDS?",
      "PED New Description": "HIV/ AIDS/ STD? *"
    },
    {
      "question_set_code": "PEDHivaidsDetails",
      "type": "subquestion",
      "question_code": "hivaidsExistingSince",
      "Question description": "Existing since? (MM/YYYY)",
      "PED New Description": "Existing since? (MM/YYYY)"
    },
    {
      "question_set_code": "yesNoExist",
      "type": "Question",
      "question_code": "pedYesNo",
      "question_title": "Does any person(s) to be insured has any Pre-existing diseases?",
      "PED New Description": "Has any Proposed to be Insured been diagnosed with or suffered from / is suffering from or is currently under medication for the following. If Your response is yes to any of the following questions, please specify details of the same in the additional information section :"
    },
    {
      "question_set_code": "HEDHealthHospitalized",
      "type": "Question",
      "question_code": "H001",
      "question_title": "Have any of the above mentioned person(s) to be insured been diagnosed / hospitalized for any illness / injury during the last 48 months?",
      "PED New Description": ""
    },
    {
      "question_set_code": "HEDHealthClaim",
      "type": "Question",
      "question_code": "H002",
      "question_title": "Have any of the person(s) to be insured ever filed a claim with their current / previous insurer?",
      "PED New Description": ""
    },
    {
      "question_set_code": "HEDHealthDeclined",
      "type": "Question",
      "question_code": "H003",
      "question_title": "Has any proposal for Health insurance been declined, cancelled or charged a higher premium?",
      "PED New Description": ""
    },
    {
      "question_set_code": "HEDHealthCovered",
      "type": "Question",
      "question_code": "H004",
      "question_title": "Is any of the person(s) to be insured, already covered under any other health insurance policy of Religare Health Insurance?",
      "PED New Description": ""
    },
    {
      "question_set_code": "PEDotherDetails",
      "type": "Question",
      "question_code": 210,
      "question_title": "Any other diseases or ailments not mentioned above ?",
      "PED New Description": ""
    },
    {
      "question_set_code": "PEDotherDetails",
      "type": "subquestion",
      "question_code": "otherExistingSince",
      "Question description": "Existing since? (MM/YYYY)",
      "PED New Description": ""
    },
    {
      "question_set_code": "PEDotherDetails",
      "type": "subquestion",
      "question_code": "otherDiseasesDescription",
      "question_title": "Any other diseases or ailments not mentioned above?",
      "PED New Description": ""
    },
    {
      "question_set_code": "PEDRespiratoryDetails",
      "type": "Question",
      "question_code": 250,
      "question_title": "Respiratory disorders inclusion?",
      "PED New Description": "Any Respiratory disease / Disease of Lungs, Pleura and airway (including but not limited to Asthma / Tuberculosis / Pleural effusion / Bronchitis / Emphysema)"
    },
    {
      "question_set_code": "PEDRespiratoryDetails",
      "type": "subquestion",
      "question_code": "respiratoryExistingSince",
      "Question description": "Existing since? (MM/YYYY)",
      "PED New Description": ""
    },
    {
      "question_set_code": "PEDEndoDetails",
      "type": "Question",
      "question_code": 222,
      "question_title": "Any disorders of the endocrine system (including but not limited to Pituitary / Parathyroid / adrenal gland disorders)",
      "PED New Description": "Any disorders of the endocrine system (including but not limited to Pituitary / Parathyroid / adrenal gland disorders)"
    },
    {
      "question_set_code": "PEDEndoDetails",
      "type": "subquestion",
      "question_code": "EndocriExistingSince",
      "question_title": "",
      "PED New Description": "Existing since? (MM/YYYY)"
    },
    {
      "question_set_code": "PEDillnessDetails",
      "type": "Question",
      "question_code": 502,
      "question_title": "Has any of the Proposed to be Insured consulted/taken treatment or recommended to take investigations/medication/surgery other than for childbirth/minor injuries? *",
      "PED New Description": "Has any of the Proposed to be Insured consulted/taken treatment or recommended to take investigations/medication/surgery other than for childbirth/minor injuries? *"
    },
    {
      "question_set_code": "PEDillnessDetails",
      "type": "subquestion",
      "question_code": "illnessExistingSince",
      "question_title": "",
      "PED New Description": "If Yes, Existing since? (MM/YYYY) *"
    },
    {
      "question_set_code": "PEDSurgeryDetails",
      "type": "Question",
      "question_code": 503,
      "question_title": "Has any of the Proposed to be Insured been hospitalized or has been under any prolonged treatment for any illness/injury or has undergone surgery other than for childbirth/minor injuries? *",
      "PED New Description": "Has any of the Proposed to be Insured been hospitalized or has been under any prolonged treatment for any illness/injury or has undergone surgery other than for childbirth/minor injuries? *"
    },
    {
      "question_set_code": "PEDSurgeryDetails",
      "type": "subquestion",
      "question_code": "SurgeryExistingSince",
      "question_title": "",
      "PED New Description": "If Yes, Existing since? (MM/YYYY) *"
    },
    {
      "question_set_code": "PEDSmokeDetails",
      "type": "Question",
      "question_code": 504,
      "question_title": "Do You smoke, consume alcohol, or chew tobacco, ghutka or paan or use any recreational drugs? If ‘Yes’ then please provide the frequency & amount consumed. *",
      "PED New Description": "Do You smoke, consume alcohol, or chew tobacco, ghutka or paan or use any recreational drugs? If ‘Yes’ then please provide the frequency & amount consumed. *"
    },
    {
      "question_set_code": "PEDSmokeDetails",
      "type": "subquestion",
      "question_code": "OtherSmokeDetails",
      "question_title": "",
      "PED New Description": "Text Box Description"
    },
    {
      "question_set_code": "PEDSmokeDetails",
      "type": "subquestion",
      "question_code": "SmokeExistingSince",
      "question_title": "",
      "PED New Description": "If Yes, Existing since? (MM/YYYY) *"
    }
  ]