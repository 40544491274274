export const environment = {
    production: false,
    api_reliance:'reliance',
    api_ckyc:'ckycService',
    api_icici:'https://rgipartners.reliancegeneral.co.in:9443',
    api_endpoint:"",
    payment_user: {
      username: "TrinityInsApp_test",
      password: "1oLsSoyFKkQ61KU9@",
      key: "a7gU7ZSfrTm8/Ktr9zcFpbknC8l7F445toeV3kvexxY=",
      iv: "D+jHPIEMbgB1Qs6HesbDGw==",
    },
    user_details: {
      clientId: 'TrinityIns',
      clientSecret: 'GEVkeuBwtQYQjPnrgaCBkMp39MezdbG2E0aonzKG1kI8chXfwObZq2Hsp0YXbGo7',
      username: 'TrinityIns',
      password: 'ej4cjdgv72eTFez',
      scope: 'esbpolicyrestriction',
      grantType: 'password',
      kycclientSecret: 'YENPLYary8VBJHhe2yXtbtQJ'
    },
    paymentApis: {
      transactionUrl: '/icici_payment/pgi/api/transaction/CreateBaseTransaction',
      paymentMethodUrl: 'https://ilesb01.insurancearticlez.com/pgi/payment/PaymentMethod',
      tokenUrl: '/icici_payment/pgi/api/token/generate',
      returnUrl: '/paymentResponse/paymentData',
      paymentVerication: '/icici_payment/pgi/api/transaction/TransactionEnquiry'
    },
    iciciApis: {
      tokenEndpoint: '/icici_token/generate-jwt-token',
      calculatePremiumEndpoint: '/api_icici/health/ilservices/health/v1/quote/getchiquote',
      checkpolicyrestrictiondetailsEndpoint: '/api_icici/health/ilservices/health/v1/generic/checkpolicyrestrictiondetails',
      getzoneendpoint: 'api_icici/health/ilservices/health/v1/generic/getzonedetail',
      proposalendpoint: '/api_icici/health/ilservices/health/v1/proposal/chicreateproposal',
      kyctokenEndpoint: '/icici_kyc_token/cerberus/connect/token',
      kycapiUrl: `/icici_kyc_token/ilservices/customer/v1/kyc/initiate`,
      icici_kyc_encrypt_data: `/encryptRoute/encrypt`,
      paymenttaggingapi: '/api_icici/common/ilservices/payment/v1/payment',
    },
    commonFixedData: {
      SubProductCode: 1164,
      TransactionType: "New",
      Zone_ProductCode: 3828,
      DealId: "DL-4193/1491884",
      SubProductName: "Apex_Plus_R",
      ChannelCode: "23",
      ChannelName: "FOS",
      ApplicationId: "375",
      Restriction_Product_code :4193,
    }
  };

  export const HDFCenvironment = {
    HDFCAPIURL :'/hdfc_url/cp//integration/heiintegrationservice/integration/',
    userDetails:{
      SOURCE: "TRINITY",
      CHANNEL_ID:"TRIN0001",
      PRODUCT_CODE : "2856",
      CREDENTIAL :"6E005500760061006700390076006400510068005A0054007A0071004B0067002B0070006F006A006E0077003D003D00",
      TRANSACTIONID:"2023080100101",
      agentId: "80180362",
      ChannelName: "HDFCLF"
     },
    kycCredential:{
      SandboxTestingkey:"8d399be8-0b6f-4a",
      APIURL: " https://ekyc-uat.hdfcergo.com/e-kyc/tgt/generate-token"
    
     },
     getKycCredential:{
      SandboxBaseUrl:"https://ekyc-uat.hdfcergo.com/e-kyc/primary/kyc-verified",
      redirect_url:"http://localhost:4200/proposal",
      Channel:"Trinity Reinsurance Brokers Limited"
     },
     paymentDetails: {
      paymentURL:'https://heapp21.hdfcergo.com/UAT/OnlineProducts/CCPGISUBSCRIBER/MakePayment.aspx',
      checksumURL : '/hdfc_payment_url/UAT/PaymentUtilitiesService/PaymentUtilities.asmx',
      appId:10323,
      SubscriptionID: 'S000000358',
      SuccessUrl: '/hdfcpaymentResponse/hdfcpaymentData',
      FailureUrl: '/hdfcpaymentResponse/hdfcpaymentData',
     }
   };

   export const  CareEnviroment = {
    partnerId: "98",
    CareAdvantage: 6312,
    PreProposalappId: "554940",
    PostProposalappId:"213661",
    Signature: "VLwAATi/myXGqlG9C14DVIKsLgFjEUAZIizPSIbVdJw=",
    CareFreedom: 5502,
    CareHeart:  5226,
    TokenpartnerId :471966,
    baseAgentId: "20008325",
    securityKey:"dkpBQ0Q3cGVGb1NXVnNsWW1EaERWb0ErQVFyTGFhSytNZCtrVzdzRGtrOW1DWktaTDdwWHRWdVZoYnpyV1JseA==",
    applicationCD:'PARTNERAPP',
    apiUrl : "https://abacus.careinsurance.com/religare_api/api/web/v1",
    api_key: "GrYpE3JLMbtP3Lw8f3JX_pI8eyVT-dyG",
    auth_secret: "tNo5)Wqs5vvS",
    careTokenUrl : "/care_api_url/relinterfacerestful/religare/secure/restful/generatePartnerToken",
    policyStatus: "/care_api_url/relinterfacerestful/religare/secure/restful/getPolicyStatusV2",
    careReturnUrl : "/hdfcpaymentResponse/hdfcpaymentData",
    downloadPolicy: "/care_api_url/relinterfacerestful/religare/secure/restful/getPolicyPDFV2",
    carePaymentUrl : "https://apiuat.religarehealthinsurance.com/portalui/PortalPayment.run",
    carePanKycUrl : "/care_api_url/relinterfacerestful/religare/secure/restful/ckycDownload",
    careAadharKycUrl:  "/care_api_url/relinterfacerestful/religare/secure/restful/aadharCKYC",
    careProposalUrl: "/care_api_url/relinterfacerestful/religare/secure/restful/createPolicy"
   }
   export const goDigitEnviroment = {
    tokenEndpoint: '/OneAPI/digit/generateAuthKey',
    quoteEndPoint: '/goDigit/QuickQuote',
    proposalEndPoint: "/goDigit/Proposal",
    goDigitAPIEndPoint: "/goDigit/",
    ProductsCode :[{"PlanName":"Double Wallet Plan", "PlanCode":"DRP01",
  "Premium":null}, {"PlanName":"Infinity Wallet Plan", "PlanCode":"IRP01","Premium":null}, {"PlanName":"World Wide Treatment Plan","PlanCode":"WTP01",  "Premium":null}],
    Authorization: "KQYWO1TRVG1ZNX7BZPY7A2B4ZMNAAH64",
    kycReturnUrl: "/payment-success",
    coverTypeRHIMR:"48718",
    coverTypeHLINP: "48717"
 }
 