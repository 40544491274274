export const hdfcOccupation =[
    {
      "STROCCUPCD": 1,
      "OCCUPATION": "ACCOUNTANT"
    },
    {
      "STROCCUPCD": 2,
      "OCCUPATION": "Accountants"
    },
    {
      "STROCCUPCD": 3,
      "OCCUPATION": "ACROBAT"
    },
    {
      "STROCCUPCD": 4,
      "OCCUPATION": "ACTUARY"
    },
    {
      "STROCCUPCD": 5,
      "OCCUPATION": "ACUPUNCTURIST"
    },
    {
      "STROCCUPCD": 6,
      "OCCUPATION": "ADMINISTRATIVE WORKER"
    },
    {
      "STROCCUPCD": 7,
      "OCCUPATION": "ADVERTISING AGENT"
    },
    {
      "STROCCUPCD": 8,
      "OCCUPATION": "ADVOCATE/LAWYER"
    },
    {
      "STROCCUPCD": 9,
      "OCCUPATION": "AERONAUTICAL ENGINEER"
    },
    {
      "STROCCUPCD": 10,
      "OCCUPATION": "Agents-Others"
    },
    {
      "STROCCUPCD": 11,
      "OCCUPATION": "Agricultural Administration"
    },
    {
      "STROCCUPCD": 12,
      "OCCUPATION": "Agricultural and Horticultural Workers"
    },
    {
      "STROCCUPCD": 13,
      "OCCUPATION": "Agricultural Others"
    },
    {
      "STROCCUPCD": 14,
      "OCCUPATION": "AGRICULTURAL WORKER / LABOURER"
    },
    {
      "STROCCUPCD": 15,
      "OCCUPATION": "AGRICULTURIST/ LAND OWNER"
    },
    {
      "STROCCUPCD": 16,
      "OCCUPATION": "Air Traffic Control"
    },
    {
      "STROCCUPCD": 17,
      "OCCUPATION": "AIR TRAFFIC CONTROLLER"
    },
    {
      "STROCCUPCD": 18,
      "OCCUPATION": "AIRCRAFT CABIN CREW"
    },
    {
      "STROCCUPCD": 19,
      "OCCUPATION": "AIRCRAFT GROUND CREW"
    },
    {
      "STROCCUPCD": 20,
      "OCCUPATION": "AIRCRAFT TECHNICIANS"
    },
    {
      "STROCCUPCD": 21,
      "OCCUPATION": "Aircraft-Others"
    },
    {
      "STROCCUPCD": 22,
      "OCCUPATION": "Aircraft-Worker"
    },
    {
      "STROCCUPCD": 23,
      "OCCUPATION": "AIRPORT SERVICE STAFF / MANAGEMENT"
    },
    {
      "STROCCUPCD": 24,
      "OCCUPATION": "Alcohol Production-Others"
    },
    {
      "STROCCUPCD": 25,
      "OCCUPATION": "Alcohol Production-Workers"
    },
    {
      "STROCCUPCD": 26,
      "OCCUPATION": "AMBULANCE ATTENDANT"
    },
    {
      "STROCCUPCD": 27,
      "OCCUPATION": "ANAESTHETIST"
    },
    {
      "STROCCUPCD": 28,
      "OCCUPATION": "Animal Handlers and Breeders"
    },
    {
      "STROCCUPCD": 29,
      "OCCUPATION": "Animal Trainers"
    },
    {
      "STROCCUPCD": 30,
      "OCCUPATION": "Animals Others"
    },
    {
      "STROCCUPCD": 31,
      "OCCUPATION": "ANIMATOR / CARTOONIST"
    },
    {
      "STROCCUPCD": 32,
      "OCCUPATION": "ANNOUNCER - Radio / Television"
    },
    {
      "STROCCUPCD": 33,
      "OCCUPATION": "ARCHAEOLOGIST"
    },
    {
      "STROCCUPCD": 34,
      "OCCUPATION": "ARCHITECT"
    },
    {
      "STROCCUPCD": 35,
      "OCCUPATION": "Architects"
    },
    {
      "STROCCUPCD": 36,
      "OCCUPATION": "Armed Forces"
    },
    {
      "STROCCUPCD": 37,
      "OCCUPATION": "Armed forces, sea going vessels Crews, Aircraft pilots and cabin crews, Actors, Heavy vehicle drivers, Machine operators"
    },
    {
      "STROCCUPCD": 38,
      "OCCUPATION": "ART TEACHER"
    },
    {
      "STROCCUPCD": 39,
      "OCCUPATION": "ARTIST"
    },
    {
      "STROCCUPCD": 40,
      "OCCUPATION": "ASBESTOS WORKER"
    },
    {
      "STROCCUPCD": 42,
      "OCCUPATION": "ASPHALT WORKER"
    },
    {
      "STROCCUPCD": 43,
      "OCCUPATION": "ASSESSOR - Insurance"
    },
    {
      "STROCCUPCD": 44,
      "OCCUPATION": "Astrologer"
    },
    {
      "STROCCUPCD": 45,
      "OCCUPATION": "AUDITOR"
    },
    {
      "STROCCUPCD": 46,
      "OCCUPATION": "AUTHOR"
    },
    {
      "STROCCUPCD": 47,
      "OCCUPATION": "Aviation-Cabin Personnel"
    },
    {
      "STROCCUPCD": 48,
      "OCCUPATION": "Aviation-Ground Personnel"
    },
    {
      "STROCCUPCD": 49,
      "OCCUPATION": "Aviation-Pilots"
    },
    {
      "STROCCUPCD": 50,
      "OCCUPATION": "BAKER / BAKERY MANAGER"
    },
    {
      "STROCCUPCD": 51,
      "OCCUPATION": "Ballooning"
    },
    {
      "STROCCUPCD": 52,
      "OCCUPATION": "BANKER"
    },
    {
      "STROCCUPCD": 53,
      "OCCUPATION": "Bankers"
    },
    {
      "STROCCUPCD": 54,
      "OCCUPATION": "BARBER / HAIRDRESSER"
    },
    {
      "STROCCUPCD": 55,
      "OCCUPATION": "BARMAN / BARMAID"
    },
    {
      "STROCCUPCD": 56,
      "OCCUPATION": "BEAUTICIAN"
    },
    {
      "STROCCUPCD": 57,
      "OCCUPATION": "Beauty Treatment"
    },
    {
      "STROCCUPCD": 58,
      "OCCUPATION": "Beauty Treatment-Others"
    },
    {
      "STROCCUPCD": 59,
      "OCCUPATION": "BEUROCRATS"
    },
    {
      "STROCCUPCD": 60,
      "OCCUPATION": "BIOCHEMIST"
    },
    {
      "STROCCUPCD": 61,
      "OCCUPATION": "BIOLOGIST"
    },
    {
      "STROCCUPCD": 62,
      "OCCUPATION": "BLASTERS"
    },
    {
      "STROCCUPCD": 63,
      "OCCUPATION": "BODYGUARD"
    },
    {
      "STROCCUPCD": 64,
      "OCCUPATION": "BOMB DISPOSAL"
    },
    {
      "STROCCUPCD": 65,
      "OCCUPATION": "BOTANIST"
    },
    {
      "STROCCUPCD": 66,
      "OCCUPATION": "Brick and Tile-Workers"
    },
    {
      "STROCCUPCD": 67,
      "OCCUPATION": "BRICKLAYER"
    },
    {
      "STROCCUPCD": 68,
      "OCCUPATION": "BROKER - Administrative"
    },
    {
      "STROCCUPCD": 69,
      "OCCUPATION": "BUILDER"
    },
    {
      "STROCCUPCD": 70,
      "OCCUPATION": "Builders"
    },
    {
      "STROCCUPCD": 71,
      "OCCUPATION": "Building and Construction Workers"
    },
    {
      "STROCCUPCD": 72,
      "OCCUPATION": "Building and Construction-Drivers and Operatives"
    },
    {
      "STROCCUPCD": 73,
      "OCCUPATION": "BUILDING CLEANER"
    },
    {
      "STROCCUPCD": 74,
      "OCCUPATION": "Building Labourer-Special Hazards"
    },
    {
      "STROCCUPCD": 75,
      "OCCUPATION": "Building-Technicians and Administrators"
    },
    {
      "STROCCUPCD": 76,
      "OCCUPATION": "BULLDOZER OPERATOR"
    },
    {
      "STROCCUPCD": 77,
      "OCCUPATION": "Bureaucrats"
    },
    {
      "STROCCUPCD": 78,
      "OCCUPATION": "BUS CONDUCTORS"
    },
    {
      "STROCCUPCD": 79,
      "OCCUPATION": "BUSINESS OWNER / MANAGER - Clerical"
    },
    {
      "STROCCUPCD": 80,
      "OCCUPATION": "CAMERAMAN  (no hazardous work)"
    },
    {
      "STROCCUPCD": 81,
      "OCCUPATION": "CAPTAIN - Fishing Boat or Trawler"
    },
    {
      "STROCCUPCD": 82,
      "OCCUPATION": "CAPTAIN - Ship"
    },
    {
      "STROCCUPCD": 83,
      "OCCUPATION": "CARDIOLOGIST"
    },
    {
      "STROCCUPCD": 84,
      "OCCUPATION": "Cargo Staff-Heavy Goods"
    },
    {
      "STROCCUPCD": 85,
      "OCCUPATION": "Cargo-Administration"
    },
    {
      "STROCCUPCD": 86,
      "OCCUPATION": "Cargo-Staff"
    },
    {
      "STROCCUPCD": 87,
      "OCCUPATION": "CARPENTER"
    },
    {
      "STROCCUPCD": 88,
      "OCCUPATION": "CASHIER"
    },
    {
      "STROCCUPCD": 89,
      "OCCUPATION": "Casino and Gambling Personnel"
    },
    {
      "STROCCUPCD": 90,
      "OCCUPATION": "Ceramics Industry-Kiln Workers"
    },
    {
      "STROCCUPCD": 91,
      "OCCUPATION": "Ceramics Industry-Workers"
    },
    {
      "STROCCUPCD": 92,
      "OCCUPATION": "CHAUFFEUR /TOURIST CAR/PRIVATE CAR/COMPANY CAR"
    },
    {
      "STROCCUPCD": 93,
      "OCCUPATION": "CHEF / COOK OTHERS"
    },
    {
      "STROCCUPCD": 94,
      "OCCUPATION": "CHEMICAL ENGINEER"
    },
    {
      "STROCCUPCD": 95,
      "OCCUPATION": "Chemical Industry-research"
    },
    {
      "STROCCUPCD": 96,
      "OCCUPATION": "CHEMIST / PHARMACIST"
    },
    {
      "STROCCUPCD": 97,
      "OCCUPATION": "CHILDCARE WORKER"
    },
    {
      "STROCCUPCD": 98,
      "OCCUPATION": "CHOREOGRAPHER"
    },
    {
      "STROCCUPCD": 99,
      "OCCUPATION": "CIRCUS PERFORMER"
    },
    {
      "STROCCUPCD": 100,
      "OCCUPATION": "Circus personal"
    },
    {
      "STROCCUPCD": 101,
      "OCCUPATION": "CIVIL ENGINEER"
    },
    {
      "STROCCUPCD": 102,
      "OCCUPATION": "Clergy and Religion"
    },
    {
      "STROCCUPCD": 103,
      "OCCUPATION": "CLERK"
    },
    {
      "STROCCUPCD": 104,
      "OCCUPATION": "Clerks"
    },
    {
      "STROCCUPCD": 105,
      "OCCUPATION": "Coal and Mining Industry-Others"
    },
    {
      "STROCCUPCD": 106,
      "OCCUPATION": "Coal and Mining Industry-Worker"
    },
    {
      "STROCCUPCD": 107,
      "OCCUPATION": "COASTGUARD"
    },
    {
      "STROCCUPCD": 108,
      "OCCUPATION": "Commercial Cleaning-Workers"
    },
    {
      "STROCCUPCD": 109,
      "OCCUPATION": "Commercial Divers"
    },
    {
      "STROCCUPCD": 110,
      "OCCUPATION": "COMPUTER ANALYST / PROGRAMMER / OPERATOR"
    },
    {
      "STROCCUPCD": 111,
      "OCCUPATION": "CONSTABLE - Police"
    },
    {
      "STROCCUPCD": 112,
      "OCCUPATION": "CONSTRUCTION WORKER"
    },
    {
      "STROCCUPCD": 113,
      "OCCUPATION": "Construction Workers"
    },
    {
      "STROCCUPCD": 114,
      "OCCUPATION": "Consulting Engineers"
    },
    {
      "STROCCUPCD": 115,
      "OCCUPATION": "Contractors"
    },
    {
      "STROCCUPCD": 116,
      "OCCUPATION": "COURIER - Car / Truck"
    },
    {
      "STROCCUPCD": 117,
      "OCCUPATION": "Craftsmen-Creative Aspects"
    },
    {
      "STROCCUPCD": 118,
      "OCCUPATION": "Culture Related"
    },
    {
      "STROCCUPCD": 119,
      "OCCUPATION": "Culture Related-Creative Aspects"
    },
    {
      "STROCCUPCD": 120,
      "OCCUPATION": "CUSTOMS OFFICIAL"
    },
    {
      "STROCCUPCD": 121,
      "OCCUPATION": "DENTIST"
    },
    {
      "STROCCUPCD": 122,
      "OCCUPATION": "Dentists"
    },
    {
      "STROCCUPCD": 123,
      "OCCUPATION": "DERMATOLOGIST"
    },
    {
      "STROCCUPCD": 124,
      "OCCUPATION": "Designer"
    },
    {
      "STROCCUPCD": 125,
      "OCCUPATION": "DETECTIVE - Private"
    },
    {
      "STROCCUPCD": 126,
      "OCCUPATION": "DIAMOND CUTTER / POLISHER"
    },
    {
      "STROCCUPCD": 127,
      "OCCUPATION": "DIETICIAN"
    },
    {
      "STROCCUPCD": 128,
      "OCCUPATION": "DIPLOMAT"
    },
    {
      "STROCCUPCD": 129,
      "OCCUPATION": "DISC JOCKEY"
    },
    {
      "STROCCUPCD": 130,
      "OCCUPATION": "DIVER"
    },
    {
      "STROCCUPCD": 131,
      "OCCUPATION": "Diver"
    },
    {
      "STROCCUPCD": 132,
      "OCCUPATION": "Dockyards-Miscellaneous"
    },
    {
      "STROCCUPCD": 133,
      "OCCUPATION": "DOCTOR"
    },
    {
      "STROCCUPCD": 134,
      "OCCUPATION": "Doctors"
    },
    {
      "STROCCUPCD": 135,
      "OCCUPATION": "DOMESTIC SERVANT"
    },
    {
      "STROCCUPCD": 136,
      "OCCUPATION": "DRAUGHTSMAN"
    },
    {
      "STROCCUPCD": 137,
      "OCCUPATION": "DRILLER"
    },
    {
      "STROCCUPCD": 138,
      "OCCUPATION": "Driver"
    },
    {
      "STROCCUPCD": 139,
      "OCCUPATION": "DRIVER -  Ambulance/Bus/Coach/Taxi/Auto Rickshaw"
    },
    {
      "STROCCUPCD": 140,
      "OCCUPATION": "DRIVER -  Others"
    },
    {
      "STROCCUPCD": 141,
      "OCCUPATION": "Driver-Commercial Vehicles"
    },
    {
      "STROCCUPCD": 142,
      "OCCUPATION": "Driving-Instructors and Examiners"
    },
    {
      "STROCCUPCD": 143,
      "OCCUPATION": "Driving-Messenger (no motorcycle)"
    },
    {
      "STROCCUPCD": 144,
      "OCCUPATION": "Dustmen and Road Cleaners"
    },
    {
      "STROCCUPCD": 145,
      "OCCUPATION": "EDITOR"
    },
    {
      "STROCCUPCD": 146,
      "OCCUPATION": "ELECTRICAL LINE WORKER"
    },
    {
      "STROCCUPCD": 147,
      "OCCUPATION": "Electrical Workers"
    },
    {
      "STROCCUPCD": 148,
      "OCCUPATION": "Electrical-Others"
    },
    {
      "STROCCUPCD": 149,
      "OCCUPATION": "ELECTRICIAN - Household Low Voltage"
    },
    {
      "STROCCUPCD": 150,
      "OCCUPATION": "Electricians"
    },
    {
      "STROCCUPCD": 151,
      "OCCUPATION": "Energy Industry"
    },
    {
      "STROCCUPCD": 152,
      "OCCUPATION": "Energy Industry-Supervision"
    },
    {
      "STROCCUPCD": 153,
      "OCCUPATION": "ENGINEER - Except mining"
    },
    {
      "STROCCUPCD": 154,
      "OCCUPATION": "ENGINEER - Mining"
    },
    {
      "STROCCUPCD": 155,
      "OCCUPATION": "Engineer-Others"
    },
    {
      "STROCCUPCD": 156,
      "OCCUPATION": "Entertainment Electronics-creative aspects"
    },
    {
      "STROCCUPCD": 157,
      "OCCUPATION": "Entertainment Electronics-No creative aspects"
    },
    {
      "STROCCUPCD": 158,
      "OCCUPATION": "Entertainment-Classical Musician"
    },
    {
      "STROCCUPCD": 159,
      "OCCUPATION": "Entertainment-Dancer"
    },
    {
      "STROCCUPCD": 160,
      "OCCUPATION": "Entertainment-Musician"
    },
    {
      "STROCCUPCD": 161,
      "OCCUPATION": "Entertainment-Performer"
    },
    {
      "STROCCUPCD": 162,
      "OCCUPATION": "Entertainment-Production and Administration"
    },
    {
      "STROCCUPCD": 163,
      "OCCUPATION": "Entertainment-Worker"
    },
    {
      "STROCCUPCD": 164,
      "OCCUPATION": "Entertainment-Writers and Composers"
    },
    {
      "STROCCUPCD": 165,
      "OCCUPATION": "ENTOMOLOGIST"
    },
    {
      "STROCCUPCD": 166,
      "OCCUPATION": "EXPLOSIVE WORKER"
    },
    {
      "STROCCUPCD": 167,
      "OCCUPATION": "Explosives Industry-Worker"
    },
    {
      "STROCCUPCD": 168,
      "OCCUPATION": "FACTORY MANAGER"
    },
    {
      "STROCCUPCD": 169,
      "OCCUPATION": "FACTORY WORKER"
    },
    {
      "STROCCUPCD": 170,
      "OCCUPATION": "Factory Workers"
    },
    {
      "STROCCUPCD": 171,
      "OCCUPATION": "Factory-Others"
    },
    {
      "STROCCUPCD": 172,
      "OCCUPATION": "FARM OWNER"
    },
    {
      "STROCCUPCD": 173,
      "OCCUPATION": "FARM WORKER"
    },
    {
      "STROCCUPCD": 174,
      "OCCUPATION": "Farmers"
    },
    {
      "STROCCUPCD": 175,
      "OCCUPATION": "Fibre or Textile Industry-Supervising Workers"
    },
    {
      "STROCCUPCD": 176,
      "OCCUPATION": "Fibre or Textile Industry-Workers"
    },
    {
      "STROCCUPCD": 177,
      "OCCUPATION": "Filing Station Attendants"
    },
    {
      "STROCCUPCD": 178,
      "OCCUPATION": "Fine Arts, Clock and Jewellery Industry-Workers"
    },
    {
      "STROCCUPCD": 179,
      "OCCUPATION": "Fire Brigade"
    },
    {
      "STROCCUPCD": 180,
      "OCCUPATION": "Fire Brigade-Others"
    },
    {
      "STROCCUPCD": 181,
      "OCCUPATION": "Fire Brigade-Very Special Duties"
    },
    {
      "STROCCUPCD": 182,
      "OCCUPATION": "FIREMAN"
    },
    {
      "STROCCUPCD": 183,
      "OCCUPATION": "FISHERMAN - Deep Sea"
    },
    {
      "STROCCUPCD": 184,
      "OCCUPATION": "FISHERMAN - Inshore"
    },
    {
      "STROCCUPCD": 185,
      "OCCUPATION": "Fishery-Administration"
    },
    {
      "STROCCUPCD": 186,
      "OCCUPATION": "Fishing Boat Crew-Captain"
    },
    {
      "STROCCUPCD": 187,
      "OCCUPATION": "Fishing Boat Crew-Others"
    },
    {
      "STROCCUPCD": 188,
      "OCCUPATION": "Fishing Industry-Miscellaneous"
    },
    {
      "STROCCUPCD": 189,
      "OCCUPATION": "Fishing Industry-Worker"
    },
    {
      "STROCCUPCD": 190,
      "OCCUPATION": "Floor-Worker"
    },
    {
      "STROCCUPCD": 191,
      "OCCUPATION": "Food Industry-Direct Contact"
    },
    {
      "STROCCUPCD": 192,
      "OCCUPATION": "Food Industry-Others"
    },
    {
      "STROCCUPCD": 193,
      "OCCUPATION": "Food Processing-Worker"
    },
    {
      "STROCCUPCD": 194,
      "OCCUPATION": "Food Production-Dangerous"
    },
    {
      "STROCCUPCD": 195,
      "OCCUPATION": "FOREMAN - Non hazardous industry"
    },
    {
      "STROCCUPCD": 196,
      "OCCUPATION": "Forestry-Others"
    },
    {
      "STROCCUPCD": 197,
      "OCCUPATION": "Forestry-Worker"
    },
    {
      "STROCCUPCD": 198,
      "OCCUPATION": "Funeral Services"
    },
    {
      "STROCCUPCD": 199,
      "OCCUPATION": "Funeral Services-Miscellaneous"
    },
    {
      "STROCCUPCD": 200,
      "OCCUPATION": "FURNACEMAN  Supervisory"
    },
    {
      "STROCCUPCD": 201,
      "OCCUPATION": "FURNACEMAN  Worker"
    },
    {
      "STROCCUPCD": 202,
      "OCCUPATION": "Furniture Industry-Workers"
    },
    {
      "STROCCUPCD": 203,
      "OCCUPATION": "GARDENER - Supervisory"
    },
    {
      "STROCCUPCD": 204,
      "OCCUPATION": "GARDENER - Worker"
    },
    {
      "STROCCUPCD": 205,
      "OCCUPATION": "Gas and Heating-Others"
    },
    {
      "STROCCUPCD": 206,
      "OCCUPATION": "Gas and Heating-Workers"
    },
    {
      "STROCCUPCD": 207,
      "OCCUPATION": "GEOLOGIST - No explosives"
    },
    {
      "STROCCUPCD": 208,
      "OCCUPATION": "Glass - Industry-Workers"
    },
    {
      "STROCCUPCD": 209,
      "OCCUPATION": "Glass-Industry-Others"
    },
    {
      "STROCCUPCD": 210,
      "OCCUPATION": "Glass-Industry-Workers with dangerous duties"
    },
    {
      "STROCCUPCD": 211,
      "OCCUPATION": "GOLDSMITH"
    },
    {
      "STROCCUPCD": 212,
      "OCCUPATION": "GUARD - Armed"
    },
    {
      "STROCCUPCD": 213,
      "OCCUPATION": "GUARD - Unarmed"
    },
    {
      "STROCCUPCD": 214,
      "OCCUPATION": "Hang gliding"
    },
    {
      "STROCCUPCD": 215,
      "OCCUPATION": "Harbour-Others"
    },
    {
      "STROCCUPCD": 216,
      "OCCUPATION": "Harbour-Worker"
    },
    {
      "STROCCUPCD": 217,
      "OCCUPATION": "HEAVY MACHINARY WORKERS"
    },
    {
      "STROCCUPCD": 218,
      "OCCUPATION": "Heavy Vehicle Drivers"
    },
    {
      "STROCCUPCD": 219,
      "OCCUPATION": "HOMEOPATH"
    },
    {
      "STROCCUPCD": 220,
      "OCCUPATION": "Hotel and Catering-Administration"
    },
    {
      "STROCCUPCD": 221,
      "OCCUPATION": "Hotel and Catering-Others"
    },
    {
      "STROCCUPCD": 222,
      "OCCUPATION": "Hotel and Catering-Workers"
    },
    {
      "STROCCUPCD": 223,
      "OCCUPATION": "Househusband"
    },
    {
      "STROCCUPCD": 225,
      "OCCUPATION": "Housekeeping"
    },
    {
      "STROCCUPCD": 228,
      "OCCUPATION": "Housewife"
    },
    {
      "STROCCUPCD": 230,
      "OCCUPATION": "Ice Skating"
    },
    {
      "STROCCUPCD": 231,
      "OCCUPATION": "Individuals with unearned income (rental or interest, pension, landlords)"
    },
    {
      "STROCCUPCD": 232,
      "OCCUPATION": "Industrial Workers"
    },
    {
      "STROCCUPCD": 233,
      "OCCUPATION": "Industry Others-Miscellaneous Workers"
    },
    {
      "STROCCUPCD": 234,
      "OCCUPATION": "Industry-Others"
    },
    {
      "STROCCUPCD": 235,
      "OCCUPATION": "INSURANCE AGENT / BROKER"
    },
    {
      "STROCCUPCD": 236,
      "OCCUPATION": "Insurance-Agent"
    },
    {
      "STROCCUPCD": 237,
      "OCCUPATION": "Interpreter"
    },
    {
      "STROCCUPCD": 238,
      "OCCUPATION": "IT-Staff"
    },
    {
      "STROCCUPCD": 239,
      "OCCUPATION": "Jockeys"
    },
    {
      "STROCCUPCD": 240,
      "OCCUPATION": "JOURNALIST - Other"
    },
    {
      "STROCCUPCD": 241,
      "OCCUPATION": "JOURNALIST - War risk"
    },
    {
      "STROCCUPCD": 242,
      "OCCUPATION": "JUDGE"
    },
    {
      "STROCCUPCD": 243,
      "OCCUPATION": "LABORATORY - Assistant / Technician"
    },
    {
      "STROCCUPCD": 244,
      "OCCUPATION": "LABOURER"
    },
    {
      "STROCCUPCD": 245,
      "OCCUPATION": "Labourers"
    },
    {
      "STROCCUPCD": 246,
      "OCCUPATION": "LATHE OPERATOR"
    },
    {
      "STROCCUPCD": 247,
      "OCCUPATION": "Laundry Service-Others"
    },
    {
      "STROCCUPCD": 248,
      "OCCUPATION": "Laundry Service-Worker"
    },
    {
      "STROCCUPCD": 249,
      "OCCUPATION": "Lawyers"
    },
    {
      "STROCCUPCD": 250,
      "OCCUPATION": "Leather-Workmanship-Workers"
    },
    {
      "STROCCUPCD": 251,
      "OCCUPATION": "LECTURER"
    },
    {
      "STROCCUPCD": 252,
      "OCCUPATION": "Legal Profession"
    },
    {
      "STROCCUPCD": 253,
      "OCCUPATION": "LIFT OPERATOR"
    },
    {
      "STROCCUPCD": 254,
      "OCCUPATION": "MAGISTRATE"
    },
    {
      "STROCCUPCD": 255,
      "OCCUPATION": "Management-Others"
    },
    {
      "STROCCUPCD": 256,
      "OCCUPATION": "Marketing"
    },
    {
      "STROCCUPCD": 257,
      "OCCUPATION": "MARTIAL ARTS"
    },
    {
      "STROCCUPCD": 258,
      "OCCUPATION": "MASON / STONEMASON"
    },
    {
      "STROCCUPCD": 259,
      "OCCUPATION": "Matchmaker"
    },
    {
      "STROCCUPCD": 260,
      "OCCUPATION": "Mechanics"
    },
    {
      "STROCCUPCD": 261,
      "OCCUPATION": "Mechanics-Others"
    },
    {
      "STROCCUPCD": 262,
      "OCCUPATION": "Medical Administration"
    },
    {
      "STROCCUPCD": 263,
      "OCCUPATION": "Medical Assistance"
    },
    {
      "STROCCUPCD": 264,
      "OCCUPATION": "Medical Doctors"
    },
    {
      "STROCCUPCD": 265,
      "OCCUPATION": "Medical-Miscellaneous"
    },
    {
      "STROCCUPCD": 266,
      "OCCUPATION": "Medical-Others"
    },
    {
      "STROCCUPCD": 267,
      "OCCUPATION": "Metal Industry-Others"
    },
    {
      "STROCCUPCD": 268,
      "OCCUPATION": "Metal Industry-Workers"
    },
    {
      "STROCCUPCD": 269,
      "OCCUPATION": "Metal Industry-Workers with dangerous duties"
    },
    {
      "STROCCUPCD": 270,
      "OCCUPATION": "METEOROLOGIST"
    },
    {
      "STROCCUPCD": 271,
      "OCCUPATION": "MIDWIFE"
    },
    {
      "STROCCUPCD": 272,
      "OCCUPATION": "MINER"
    },
    {
      "STROCCUPCD": 273,
      "OCCUPATION": "MINER - Administration"
    },
    {
      "STROCCUPCD": 274,
      "OCCUPATION": "MODEL"
    },
    {
      "STROCCUPCD": 275,
      "OCCUPATION": "Mountaineering"
    },
    {
      "STROCCUPCD": 276,
      "OCCUPATION": "Musical Instrument Specialists"
    },
    {
      "STROCCUPCD": 277,
      "OCCUPATION": "MUSICIAN"
    },
    {
      "STROCCUPCD": 278,
      "OCCUPATION": "Navigation-Administration"
    },
    {
      "STROCCUPCD": 279,
      "OCCUPATION": "Navigation-Inland Water"
    },
    {
      "STROCCUPCD": 280,
      "OCCUPATION": "Navigation-Off Shore-No Special Duties"
    },
    {
      "STROCCUPCD": 281,
      "OCCUPATION": "Navigation-Off Shore-Ship's Crew"
    },
    {
      "STROCCUPCD": 282,
      "OCCUPATION": "Navigation-Others"
    },
    {
      "STROCCUPCD": 283,
      "OCCUPATION": "Navigation-Pilots and Lighthousemen"
    },
    {
      "STROCCUPCD": 284,
      "OCCUPATION": "NURSE"
    },
    {
      "STROCCUPCD": 285,
      "OCCUPATION": "OBSTETRICIAN / GYNAECOLOGIST"
    },
    {
      "STROCCUPCD": 286,
      "OCCUPATION": "Occupation Class 1"
    },
    {
      "STROCCUPCD": 287,
      "OCCUPATION": "Occupation Class 1"
    },
    {
      "STROCCUPCD": 288,
      "OCCUPATION": "Occupation Class 2"
    },
    {
      "STROCCUPCD": 289,
      "OCCUPATION": "Occupation Class 2"
    },
    {
      "STROCCUPCD": 290,
      "OCCUPATION": "Occupation Class 3"
    },
    {
      "STROCCUPCD": 291,
      "OCCUPATION": "Occupation Class 3"
    },
    {
      "STROCCUPCD": 292,
      "OCCUPATION": "Occupation Class 4"
    },
    {
      "STROCCUPCD": 293,
      "OCCUPATION": "Occupation Class 4"
    },
    {
      "STROCCUPCD": 294,
      "OCCUPATION": "Occupation Class 5"
    },
    {
      "STROCCUPCD": 295,
      "OCCUPATION": "Occupation Class 5"
    },
    {
      "STROCCUPCD": 296,
      "OCCUPATION": "Occupation Class 6"
    },
    {
      "STROCCUPCD": 297,
      "OCCUPATION": "Occupation Class 6"
    },
    {
      "STROCCUPCD": 298,
      "OCCUPATION": "Office Executives"
    },
    {
      "STROCCUPCD": 299,
      "OCCUPATION": "Office Workers and Commercial Clerks"
    },
    {
      "STROCCUPCD": 300,
      "OCCUPATION": "Office Workers-Craftsmen"
    },
    {
      "STROCCUPCD": 301,
      "OCCUPATION": "OIL RIG WORKER - Manual"
    },
    {
      "STROCCUPCD": 302,
      "OCCUPATION": "OIL RIG WORKER - Non Manual e.g.. Engineer"
    },
    {
      "STROCCUPCD": 303,
      "OCCUPATION": "Oil Rig-Flying Personnel"
    },
    {
      "STROCCUPCD": 304,
      "OCCUPATION": "Oil Rig-Management and Control"
    },
    {
      "STROCCUPCD": 305,
      "OCCUPATION": "Oil Rig-Others"
    },
    {
      "STROCCUPCD": 306,
      "OCCUPATION": "Oil Rig-Workers with dangerous duties"
    },
    {
      "STROCCUPCD": 307,
      "OCCUPATION": "Oil Rig-Workers with very dangerous duties"
    },
    {
      "STROCCUPCD": 308,
      "OCCUPATION": "On-site engineers"
    },
    {
      "STROCCUPCD": 309,
      "OCCUPATION": "Optical Industry-Others"
    },
    {
      "STROCCUPCD": 310,
      "OCCUPATION": "Optical Industry-Workers"
    },
    {
      "STROCCUPCD": 311,
      "OCCUPATION": "OPTOMETRIST / OPTICIAN / TECHNICIAN"
    },
    {
      "STROCCUPCD": 312,
      "OCCUPATION": "ORTHODONTIST"
    },
    {
      "STROCCUPCD": 313,
      "OCCUPATION": "ORTHOPAEDIC SURGEON"
    },
    {
      "STROCCUPCD": 314,
      "OCCUPATION": "Others PA"
    },
    {
      "STROCCUPCD": 315,
      "OCCUPATION": "Paper Industry-Workers"
    },
    {
      "STROCCUPCD": 316,
      "OCCUPATION": "PATHOLOGIST"
    },
    {
      "STROCCUPCD": 317,
      "OCCUPATION": "Personal Car Drivers"
    },
    {
      "STROCCUPCD": 318,
      "OCCUPATION": "Persons working inside offices/shops without exposure to working in the open, manual labour or regular on-road travel."
    },
    {
      "STROCCUPCD": 319,
      "OCCUPATION": "Persons working outside office/shops involving mild manual work, supervision of manual labour or regular on-road travel."
    },
    {
      "STROCCUPCD": 320,
      "OCCUPATION": "PETROL STATION PUMP ATTENDANT"
    },
    {
      "STROCCUPCD": 321,
      "OCCUPATION": "PHARMACIST/CHEMIST"
    },
    {
      "STROCCUPCD": 322,
      "OCCUPATION": "PHOTOGRAPHER"
    },
    {
      "STROCCUPCD": 323,
      "OCCUPATION": "Photography-Technical"
    },
    {
      "STROCCUPCD": 324,
      "OCCUPATION": "PHYSIOTHERAPIST"
    },
    {
      "STROCCUPCD": 325,
      "OCCUPATION": "PILOT Airline"
    },
    {
      "STROCCUPCD": 326,
      "OCCUPATION": "PILOT Harbour"
    },
    {
      "STROCCUPCD": 327,
      "OCCUPATION": "PIPELINE WORKER"
    },
    {
      "STROCCUPCD": 328,
      "OCCUPATION": "Plastics Industry-Supervision"
    },
    {
      "STROCCUPCD": 329,
      "OCCUPATION": "Plastics Industry-Worker"
    },
    {
      "STROCCUPCD": 330,
      "OCCUPATION": "PLUMBER"
    },
    {
      "STROCCUPCD": 331,
      "OCCUPATION": "POLICE PROFESSIONALS"
    },
    {
      "STROCCUPCD": 332,
      "OCCUPATION": "Police, occupation or nature of job involve working in mines, with explosive, oil/gas/metal/power or chemical production, professional sports, high voltage electricity, handling of heavy machinery or hazardous materials, heat or noise or working at heights or significant manual labor."
    },
    {
      "STROCCUPCD": 333,
      "OCCUPATION": "Polo playing"
    },
    {
      "STROCCUPCD": 334,
      "OCCUPATION": "PORTER"
    },
    {
      "STROCCUPCD": 335,
      "OCCUPATION": "POST OFFICE  - Clerical Staff"
    },
    {
      "STROCCUPCD": 336,
      "OCCUPATION": "Postal Service-Others"
    },
    {
      "STROCCUPCD": 337,
      "OCCUPATION": "Postal Service-Workers"
    },
    {
      "STROCCUPCD": 338,
      "OCCUPATION": "POSTMAN"
    },
    {
      "STROCCUPCD": 339,
      "OCCUPATION": "Pottery Worker"
    },
    {
      "STROCCUPCD": 340,
      "OCCUPATION": "Pottery Worker with dangerous duties"
    },
    {
      "STROCCUPCD": 341,
      "OCCUPATION": "Press Coverage-No foreign assignments"
    },
    {
      "STROCCUPCD": 342,
      "OCCUPATION": "PRIEST"
    },
    {
      "STROCCUPCD": 343,
      "OCCUPATION": "Printing Industry-Others"
    },
    {
      "STROCCUPCD": 344,
      "OCCUPATION": "Printing Industry-Workers"
    },
    {
      "STROCCUPCD": 345,
      "OCCUPATION": "Printing Industry-Workers with dangerous duties"
    },
    {
      "STROCCUPCD": 346,
      "OCCUPATION": "Professional sports teams"
    },
    {
      "STROCCUPCD": 347,
      "OCCUPATION": "PROFESSIONAL SPORTSMAN"
    },
    {
      "STROCCUPCD": 348,
      "OCCUPATION": "Property - Landlord"
    },
    {
      "STROCCUPCD": 351,
      "OCCUPATION": "Property Owner"
    },
    {
      "STROCCUPCD": 352,
      "OCCUPATION": "PSYCHIATRIST"
    },
    {
      "STROCCUPCD": 353,
      "OCCUPATION": "PSYCHOLOGIST"
    },
    {
      "STROCCUPCD": 354,
      "OCCUPATION": "Public Health Service"
    },
    {
      "STROCCUPCD": 355,
      "OCCUPATION": "Public Health Service-Pest Control"
    },
    {
      "STROCCUPCD": 356,
      "OCCUPATION": "Public Services-Not Office"
    },
    {
      "STROCCUPCD": 357,
      "OCCUPATION": "Public Services-Others"
    },
    {
      "STROCCUPCD": 358,
      "OCCUPATION": "Public Services-Prison"
    },
    {
      "STROCCUPCD": 359,
      "OCCUPATION": "Public Services-Security [Armed - No Personal Security]"
    },
    {
      "STROCCUPCD": 360,
      "OCCUPATION": "Public Services-Security [Not Armed]"
    },
    {
      "STROCCUPCD": 361,
      "OCCUPATION": "Public Services-Security [Personal Security]"
    },
    {
      "STROCCUPCD": 362,
      "OCCUPATION": "QUARRY WORKER - Blasting"
    },
    {
      "STROCCUPCD": 363,
      "OCCUPATION": "QUARRY WORKER - Non Blasting"
    },
    {
      "STROCCUPCD": 364,
      "OCCUPATION": "Race show participants"
    },
    {
      "STROCCUPCD": 365,
      "OCCUPATION": "Radio and Television"
    },
    {
      "STROCCUPCD": 366,
      "OCCUPATION": "RADIOLOGIST / X-RAY TECHNICIAN"
    },
    {
      "STROCCUPCD": 367,
      "OCCUPATION": "RAILWAY LINESMAN - high voltage"
    },
    {
      "STROCCUPCD": 368,
      "OCCUPATION": "RAILWAY PROFESSIONAL"
    },
    {
      "STROCCUPCD": 369,
      "OCCUPATION": "RAILWAY WORKER"
    },
    {
      "STROCCUPCD": 370,
      "OCCUPATION": "Railways-Drivers and Guards"
    },
    {
      "STROCCUPCD": 371,
      "OCCUPATION": "Railways-Others"
    },
    {
      "STROCCUPCD": 372,
      "OCCUPATION": "Railway-Supervising Workers"
    },
    {
      "STROCCUPCD": 373,
      "OCCUPATION": "Railway-Workers"
    },
    {
      "STROCCUPCD": 374,
      "OCCUPATION": "Railway-Workers with less dangerous duties"
    },
    {
      "STROCCUPCD": 375,
      "OCCUPATION": "REFINERY WORKER - oil / gas"
    },
    {
      "STROCCUPCD": 376,
      "OCCUPATION": "REFINERY WORKER - sugarcane"
    },
    {
      "STROCCUPCD": 377,
      "OCCUPATION": "Retail and Sales"
    },
    {
      "STROCCUPCD": 378,
      "OCCUPATION": "Retail and Sales-Others"
    },
    {
      "STROCCUPCD": 379,
      "OCCUPATION": "Retired"
    },
    {
      "STROCCUPCD": 382,
      "OCCUPATION": "River Rafting"
    },
    {
      "STROCCUPCD": 383,
      "OCCUPATION": "Road Safety-Officer"
    },
    {
      "STROCCUPCD": 384,
      "OCCUPATION": "Road Transport-Others"
    },
    {
      "STROCCUPCD": 385,
      "OCCUPATION": "ROOF TILER"
    },
    {
      "STROCCUPCD": 386,
      "OCCUPATION": "Rubber Industry-Others"
    },
    {
      "STROCCUPCD": 387,
      "OCCUPATION": "Rubber Industry-Workers"
    },
    {
      "STROCCUPCD": 388,
      "OCCUPATION": "Rubber Industry-Workers with dangerous duties"
    },
    {
      "STROCCUPCD": 389,
      "OCCUPATION": "Salesmen"
    },
    {
      "STROCCUPCD": 390,
      "OCCUPATION": "Scientists-predominantly indoor"
    },
    {
      "STROCCUPCD": 391,
      "OCCUPATION": "Scientists-predominantly indoor and  humanistic"
    },
    {
      "STROCCUPCD": 392,
      "OCCUPATION": "SECRETARY / TYPIST"
    },
    {
      "STROCCUPCD": 393,
      "OCCUPATION": "Security Service-Guards [Armed - No Personal Security]"
    },
    {
      "STROCCUPCD": 394,
      "OCCUPATION": "Security Service-Guards [Not Armed]"
    },
    {
      "STROCCUPCD": 395,
      "OCCUPATION": "Security Service-Guards [Personal Security]"
    },
    {
      "STROCCUPCD": 396,
      "OCCUPATION": "Semi or Unskilled workers, skilled laborers, low voltage electricians, drivers, automated machine operators with moderate to heavy manual work working in workshops or in the open"
    },
    {
      "STROCCUPCD": 397,
      "OCCUPATION": "SHEET METAL WORKER"
    },
    {
      "STROCCUPCD": 398,
      "OCCUPATION": "Ship Building-Others"
    },
    {
      "STROCCUPCD": 399,
      "OCCUPATION": "Ship Building-Workers"
    },
    {
      "STROCCUPCD": 400,
      "OCCUPATION": "Ship crews"
    },
    {
      "STROCCUPCD": 401,
      "OCCUPATION": "Shopkeepers and Traders"
    },
    {
      "STROCCUPCD": 402,
      "OCCUPATION": "SINGER"
    },
    {
      "STROCCUPCD": 403,
      "OCCUPATION": "Skiing"
    },
    {
      "STROCCUPCD": 404,
      "OCCUPATION": "Sports Related"
    },
    {
      "STROCCUPCD": 405,
      "OCCUPATION": "Sports-Coach"
    },
    {
      "STROCCUPCD": 406,
      "OCCUPATION": "Sports-Miscellaneous"
    },
    {
      "STROCCUPCD": 410,
      "OCCUPATION": "Students"
    },
    {
      "STROCCUPCD": 411,
      "OCCUPATION": "STUNT MAN"
    },
    {
      "STROCCUPCD": 412,
      "OCCUPATION": "Stunt show participants"
    },
    {
      "STROCCUPCD": 413,
      "OCCUPATION": "SURGEON"
    },
    {
      "STROCCUPCD": 414,
      "OCCUPATION": "SURVEYOR"
    },
    {
      "STROCCUPCD": 415,
      "OCCUPATION": "Synthetic Material-Worker"
    },
    {
      "STROCCUPCD": 416,
      "OCCUPATION": "TAILOR"
    },
    {
      "STROCCUPCD": 417,
      "OCCUPATION": "Taxi Drivers"
    },
    {
      "STROCCUPCD": 418,
      "OCCUPATION": "TEACHER"
    },
    {
      "STROCCUPCD": 419,
      "OCCUPATION": "Teacher-normal career"
    },
    {
      "STROCCUPCD": 420,
      "OCCUPATION": "Teacher-Others [not predominantly artistic]"
    },
    {
      "STROCCUPCD": 421,
      "OCCUPATION": "Teacher-Others [predominantly artistic]"
    },
    {
      "STROCCUPCD": 422,
      "OCCUPATION": "Teacher-upscaled career"
    },
    {
      "STROCCUPCD": 423,
      "OCCUPATION": "Technicians"
    },
    {
      "STROCCUPCD": 424,
      "OCCUPATION": "Technicians-Others"
    },
    {
      "STROCCUPCD": 425,
      "OCCUPATION": "Telecommunication"
    },
    {
      "STROCCUPCD": 426,
      "OCCUPATION": "Telecommunication-Others"
    },
    {
      "STROCCUPCD": 427,
      "OCCUPATION": "Textile Industry-Creative Aspects"
    },
    {
      "STROCCUPCD": 428,
      "OCCUPATION": "Textile Industry-Others"
    },
    {
      "STROCCUPCD": 429,
      "OCCUPATION": "Textile Industry-Workers"
    },
    {
      "STROCCUPCD": 430,
      "OCCUPATION": "Tobacco Industry-Others"
    },
    {
      "STROCCUPCD": 431,
      "OCCUPATION": "Tobacco Industry-Workers"
    },
    {
      "STROCCUPCD": 432,
      "OCCUPATION": "Tourism"
    },
    {
      "STROCCUPCD": 433,
      "OCCUPATION": "Traders-Others"
    },
    {
      "STROCCUPCD": 434,
      "OCCUPATION": "TRAFFIC OFFICER / INSPECTOR / WARDEN"
    },
    {
      "STROCCUPCD": 435,
      "OCCUPATION": "TRAWLERMAN - Deep-sea"
    },
    {
      "STROCCUPCD": 436,
      "OCCUPATION": "TRAWLERMAN - Inshore"
    },
    {
      "STROCCUPCD": 437,
      "OCCUPATION": "TUTOR"
    },
    {
      "STROCCUPCD": 438,
      "OCCUPATION": "Typist"
    },
    {
      "STROCCUPCD": 439,
      "OCCUPATION": "Tyre Industry-Workers"
    },
    {
      "STROCCUPCD": 440,
      "OCCUPATION": "UNDERWRITER"
    },
    {
      "STROCCUPCD": 441,
      "OCCUPATION": "Unemployed"
    },
    {
      "STROCCUPCD": 443,
      "OCCUPATION": "Unskilled Labourers"
    },
    {
      "STROCCUPCD": 444,
      "OCCUPATION": "UROLOGIST"
    },
    {
      "STROCCUPCD": 445,
      "OCCUPATION": "Vehicle Industry-Assembly and Maintenance"
    },
    {
      "STROCCUPCD": 446,
      "OCCUPATION": "Vehicle-Tester"
    },
    {
      "STROCCUPCD": 447,
      "OCCUPATION": "VETERINARIAN"
    },
    {
      "STROCCUPCD": 448,
      "OCCUPATION": "Veterinary Doctors"
    },
    {
      "STROCCUPCD": 449,
      "OCCUPATION": "WAITER / WAITRESS"
    },
    {
      "STROCCUPCD": 450,
      "OCCUPATION": "Water Supply-Miscellaneous"
    },
    {
      "STROCCUPCD": 451,
      "OCCUPATION": "Water Supply-Workers"
    },
    {
      "STROCCUPCD": 452,
      "OCCUPATION": "Weapons Production"
    },
    {
      "STROCCUPCD": 453,
      "OCCUPATION": "WEAVER"
    },
    {
      "STROCCUPCD": 454,
      "OCCUPATION": "WELDER"
    },
    {
      "STROCCUPCD": 455,
      "OCCUPATION": "Winter Sports"
    },
    {
      "STROCCUPCD": 456,
      "OCCUPATION": "Wood Processing-Workers"
    },
    {
      "STROCCUPCD": 457,
      "OCCUPATION": "Wood Processing-Workers with dangerous duties"
    },
    {
      "STROCCUPCD": 458,
      "OCCUPATION": "Workers (bodily)-General"
    },
    {
      "STROCCUPCD": 459,
      "OCCUPATION": "Workers [With no special hazards]"
    },
    {
      "STROCCUPCD": 460,
      "OCCUPATION": "X-RAY TECHNICIAN / RADIOLOGIST"
    },
    {
      "STROCCUPCD": 461,
      "OCCUPATION": "ZOOLOGIST"
    }
  ]
