export const medicalQuestionaire =  [
    {
        "question_title" :"Hypertension/ High blood pressure?",
        "DiseaseID":"1001",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
        "IsMedicalQuestionOpted":""
    },
    {
        "question_title" :"Diabetes/ High blood sugar/Sugar in urine?",
        "DiseaseID":"1002",
        'LastConsultationDate':"",
        "CurrentStatus":"",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Cancer, Tumour, Growth or Cyst of any kind?",
        "DiseaseID":"1003",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Chest Pain/ Heart Attack or any other Heart Disease/ Problem?",
        "DiseaseID":"1004",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Liver or Gall Bladder ailment/Jaundice/Hepatitis B or C?",
        "DiseaseID":"1005",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :" Kidney ailment or Diseases of Reproductive organs?",
        "DiseaseID":"1006",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Tuberculosis/ Asthma or any other Lung disorder?",
        "DiseaseID":"1007",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Ulcer (Stomach/ Duodenal), or any ailment of Digestive System?",
        "DiseaseID":"1008",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Any Blood disorder (example Anaemia, Haemophilia, Thalassaemia) or any genetic disorder?",
        "DiseaseID":"1009",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"HIV Infection/AIDS or Positive test for HIV?",
        "DiseaseID":"1010",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Nervous, Psychiatric or Mental or Sleep disorder?",
        "DiseaseID":"1011",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Stroke/ Paralysis/ Epilepsy (Fits) or any other Nervous disorder (Brain / Spinal Cord etc.)?",
        "DiseaseID":"1012",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Abnormal Thyroid Function/ Goiter or any Endocrine organ disorders?",
        "DiseaseID":"1013",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :" Eye or vision disorders/ Ear/ Nose or Throat diseases?",
        "DiseaseID":"1014",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Arthritis, Spondylitis, Fracture or any other disorder of Muscle Bone/ Joint/ Ligament/ Cartilage?",
        "DiseaseID":"1015",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Any other disease/condition not mentioned above?",
        "DiseaseID":"1016",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Has planned a surgery?",
        "DiseaseID":"2",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Takes medicines regularly?",
        "DiseaseID":"3",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Has been advised investigation or further tests?",
        "DiseaseID":"4",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Was hospitalized in the past?",
        "DiseaseID":"5",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"Is Pregnant?",
        "DiseaseID":"6",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    },
    {
        "question_title" :"None of the above",
        "DiseaseID":"7",
        "ExactDiagnosis":"",
        "DiagnosisDate":"",
    }
]